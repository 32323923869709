import React, { useEffect, useRef, useState } from 'react'
import { BsCheck2Circle } from 'react-icons/bs';
import OtpInput from 'react-otp-input';
import { BASE_URL_API } from '../../References/URLs';
import styles from '../UI/Modals.module.css'

const Modals = props => {
    // const [phone, setPhone] = useState(props.phone != undefined ? props.phone : '');
    let phoneVerificationHandler = props.phoneVerificationHandler;
    let setPhoneHandler = props.setPhoneHandler;
    let fullName = props.fullName
    let venue = props.venue;
    let weddingDate = props.weddingDate;

    const fullNameHandler = props.fullNameHandler;
    const venueHandler = props.venueHandler;
    const weddingDateHandler = props.weddingDateHandler;

    const [phone, setPhone] = useState(props.phone);
    const [otp, setOtp] = useState({ otp: "" });
    const [isOtpSent, setIsOtpSent] = useState(false) // sending, true, false
    const [otpVerificationStatus, setOtpVerificationStatus] = useState('NOT_VERIFIED'); // NOT_VERIFIED, BEING_VERIFIED, VERIFIED, INVALID_OTP
    const [sessionKey, setSessionKey] = useState('');
    const [seconds, setSeconds] = useState(45);

    const modalStateHandler = props.modalStateHandler;
    const modalRef = useRef(null);
    let isOpen = props.isOpen;
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = "hidden";
            document.getElementById("mainModal").style.display = "block";
            document.getElementById("mainModalContent").style.animationName = "slideIn";
            document.getElementById("mainModalContent").style.animationDuration = "0.4s";
        }
    }, [isOpen])

    window.onclick = function (event) {
        if (event.target.id == "mainModal") {
            setOtpVerificationStatus("NOT_VERIFIED");
            document.body.style.overflowY = "auto";
            document.getElementById("mainModal").style.display = "none";
            modalStateHandler(false)
            setIsOtpSent(false);
            // document.getElementById("mainModalContent").style.animationName = "slideIn";
            // document.getElementById("mainModalContent").style.animationDuration = "0.4s";
        }
    }

    const handleChange = (otp) => {
        setOtpVerificationStatus("NOT_VERIFIED");
        setOtp({ otp });

    }

    useEffect(() => {
        if (phone.length == 10) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds => seconds - 1);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isOtpSent]);


    const sendOtpHandler = async () => {
        if(fullName.length>2) {
            if(phone.length==10) {
                setIsOtpSent('SENDING');
                setPhoneHandler(phone);
                setSeconds(45);
                const sendOtpResponse = await fetch(BASE_URL_API + "/sendOtp",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            phone: phone,
                            sessionKey: sessionKey
                        })
                    });
        
                if (!sendOtpResponse.ok) {
                    console.log("Something went wrong : Server Error!");
                    setIsOtpSent(false);
                } else {
                    const sendOtpRespo = await sendOtpResponse.json();
                    setSessionKey(sendOtpRespo.response.token)
                    setIsOtpSent(true)
                }
            } else {
                alert("Please enter a 10 digit valid phone number");
            }
        } else {
            alert("Please enter a valid name..");
        }

    }

    const verifyOtp = async () => {
        setOtpVerificationStatus("BEING_VERIFIED")
        const verifyOtpResponse = await fetch(BASE_URL_API + "/verifyOtp",
            {
                method: "POST",
                body: JSON.stringify({
                    phone: phone,
                    token: sessionKey,
                    otp: otp.otp
                })
            });

        if (!verifyOtpResponse.ok) {
            console.log("Something went wrong, server Error!");
        } else {
            const verifyOtpRespo = await verifyOtpResponse.json();
            if (verifyOtpRespo.status == "success") {
                setOtpVerificationStatus("VERIFIED")
                phoneVerificationHandler(true);
            } else {
                if (verifyOtpRespo.message == "otpExpired") {
                    setOtpVerificationStatus("OTP_EXPIRED");
                    setTimeout(() => {
                        document.getElementById("mainModal").click();
                    }, 3000);
                } else {
                    setOtpVerificationStatus("INVALID_OTP");
                }
            }
        }
    }

    const resendOtpHandler = () => {
        sendOtpHandler();
    }

    const submitOtpHandler = () => {
        // setOtpVerificationStatus("BEING_VERIFIED")
        // setOtpVerificationStatus("VERIFIED")
        // setOtpVerificationStatus("INVALID_OTP")
        verifyOtp();
    }

    useEffect(() => {
        if (otp.otp.length == 4) {
            verifyOtp()
        }
    }, [otp])

    return (
        <div className={styles.mainModal} id="mainModal" ref={modalRef}>
            <div className={styles.modalContent} id="mainModalContent">
                {isOtpSent == false ?
                    <div className={styles.enterPhoneWrapper}>
                        <div className={styles.enterPhoneContainer}>
                            <div className={styles.eachInputWrapper}>
                                <div className={styles.label}>Full Name</div>
                                <input
                                    type="text"
                                    placeholder="Please enter your full name"
                                    value={fullName}
                                    onChange={(e) => fullNameHandler(e.target.value)}
                                    autoFocus={true}
                                />
                            </div>

                            <div className={styles.eachInputWrapper}>
                                <div className={styles.label}>Location</div>
                                <input
                                    type="text"
                                    placeholder="Wedding Location / Venue"
                                    value={venue}
                                    onChange={(e) => venueHandler(e.target.value)}
                                />
                            </div>

                            <div className={styles.eachInputWrapper}>
                                <div className={styles.label}>Marriage date</div>
                                <input
                                    placeholder="Please select a tentative date..."
                                    type="date"
                                    value={weddingDate}
                                    onChange={(e) => weddingDateHandler(e.target.value)}
                                />
                            </div>

                            <div className={styles.eachInputWrapper}>
                                <div className={styles.label}>Phone</div>
                                <input
                                    type="number"
                                    placeholder="Please enter 10 digit number to verify.."
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                    
                                />
                            </div>

                            <div>
                                {isOtpSent == 'SENDING' ?
                                    <button>
                                        Sending OTP...
                                    </button>
                                    :
                                    <button onClick={() => sendOtpHandler()}>
                                        Submit
                                    </button>
                                }
                                <div className={styles.cancel}
                                    onClick={() => {
                                        document.getElementById("mainModal").click();
                                    }}
                                >
                                    Cancel
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    otpVerificationStatus == "VERIFIED" ?
                        <div className={styles.verificationWrapper}>
                            <div className={styles.verifiedContent}>
                                <BsCheck2Circle
                                    color="green"
                                    size={40}
                                />
                            </div>
                            <div className={styles.verifiedMessage}>
                                <p>
                                    You have successfully verified the phone Number!
                                </p>
                                <p>
                                    Our team will be in touch with you soon!
                                </p>
                            </div>
                        </div>
                        :
                        <div className={styles.otpEnterContainer}>
                            <div className={styles.sentOnLine}>
                                <div className={styles.sentOnLineTxt}>
                                    We have sent an OTP (One Time Password) to {phone}!
                                </div>
                                <div className={styles.changeNumber} onClick={() => setIsOtpSent(false)}>
                                    Change
                                </div>
                            </div>
                            <div className={styles.enterOtpWrapper}>
                                <div className={styles.enterOtpCaption}>
                                    Enter the OTP received!
                                </div>
                                <div>
                                    <OtpInput
                                        value={otp.otp}
                                        onChange={(otp) => handleChange(otp)}
                                        numInputs={4}
                                        separator={<span>-</span>}
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        inputStyle={{
                                            border: '1px solid #00000065',
                                            padding: '8px 10px',
                                            borderRadius: '4px',
                                            margin: '0px 4px'
                                        }}
                                    />
                                </div>

                            </div>
                            <div className={styles.submitOtpWrapper}>
                                {otpVerificationStatus == 'NOT_VERIFIED' ?
                                    <div>
                                        {otp.otp.length > 3 ?
                                            <button className={styles.submitButton}
                                                onClick={() => submitOtpHandler()}
                                            >
                                                Submit
                                            </button>
                                            :
                                            <button className={styles.submitBtnDull}
                                                disabled
                                            >
                                                Submit
                                            </button>
                                        }
                                    </div>
                                    : otpVerificationStatus == "BEING_VERIFIED" ?
                                        <div className={styles.beingVerifiedTxt}>
                                            Being verified
                                        </div>
                                        : otpVerificationStatus == "INVALID_OTP" ?
                                            <div>
                                                <div className={styles.invalidOtpMessage}>
                                                    Invalid Otp
                                                </div>
                                                <div>
                                                    <button className={styles.submitButton}
                                                        onClick={() => submitOtpHandler()}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                            : otpVerificationStatus == "OTP_EXPIRED" &&
                                            <div>
                                                <div className={styles.otpExpiredTxt}>
                                                    <p>Otp Expired, please try again!</p>
                                                    <h6>Refreshing the page in few seconds</h6>
                                                </div>
                                            </div>

                                }
                            </div>
                            <div className={styles.havenotReceivedWrapper}>
                                <div>
                                    Haven't received the OTP yet?
                                </div>
                                {seconds > 0 ?
                                    <div className={styles.resendOtpTxt}>
                                        Resend OTP in {seconds}
                                    </div>
                                    :
                                    <button className={styles.resendOtpBtn}
                                        onClick={() => resendOtpHandler()}
                                    >
                                        Resend OTP
                                    </button>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default Modals
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import styles from '../Headers/AtPreBridalMakeups.module.css'
import { useNavigate } from 'react-router';


const AtPreBridalMakeups = props => {
    let navigate = useNavigate()
    const refCall = useRef();
    const [menuData, setMenuData] = useState([
        {
            "title" : 'Bridal Make-up',
            "navigateTo" : '/bridal-makeup'
        },
        // {
        //     "title" : 'Mehandi',
        //     "navigateTo" : ''
        // }
    ])

    const makeACallNow = () => {
        refCall.current.click()
        // href="tel:+91-8929460356"
    }
    
    return (
        <div className={styles.headerTransWrapper}>
            <div className={styles.htwLeft}>
                <Link to="/" className={styles.mainLogo}>
                    <img
                        src="https://res.cloudinary.com/drotwjbzs/image/upload/v1662725527/lokaci_logo_white_dndg2v.png" />
                </Link>
            </div>
            <div className={styles.htwCenter}>
                <div className={styles.menuWrapper}>
                    {menuData.map((item, index) => (
                        <Link to={item.navigateTo} className={styles.eachMenu}>
                            {item.title}
                        </Link>
                    ))}
                </div>
            </div>

            <div className={styles.htwRight}>
                <button className={styles.loginBtn} 
                    onClick={() => makeACallNow()}
                >
                    Call Now
                </button>
                <a href="tel:+91-8929460356" ref={refCall}></a>
            </div>

        </div>
    )
}

export default AtPreBridalMakeups
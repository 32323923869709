import React, { useEffect, useState } from 'react'
import styles from '../PreBridal/BookPreBridalConsultation.module.css';

const BookPreBridalConsultation = () => {
    const [selectedConsultedFor, setSelectedConsultedFor] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const selectConsultedForHandler = k => {
        if (selectedConsultedFor.includes(k)) {
            setSelectedConsultedFor(selectedConsultedFor.filter((p) => p != k));
        } else {
            setSelectedConsultedFor([...selectedConsultedFor, k])
        }
    }

    const validateFields = () => {
        if (fullName.length > 2) {
            if (email.length > 8) {
                if (phoneNumber.length == 10) {
                    if (selectedConsultedFor.length > 0) {
                        setIsDisabled(false);
                    } else {
                        setIsDisabled(true);
                    }
                } else {
                    setIsDisabled(true)
                }
            } else {
                setIsDisabled(true)
            }
        } else {
            setIsDisabled(true)
        }
    }

    useEffect(() => {
        validateFields()
    }, [fullName, email, phoneNumber, selectedConsultedFor])
    
    const submitConsultationRequest = () => {
        if(!isDisabled) {
            console.log(fullName, email, phoneNumber, selectedConsultedFor)
        } else {
            alert("Please enter all the details required");
        }
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.titleContainer}>
                        <h2>
                            Exclusive
                        </h2>
                        <h1>
                            Pre-Bridal Consultation
                        </h1>
                    </div>
                    <div className={styles.contentContainer}>
                        <p>
                            We provide Pre-Bridal Consultation on various things such as the best services per your skin/hair type, diet plan to be followed before D-Day, everyday skincare routine to look radiant, and many more amazing benefits in-stored.
                        </p>
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.formWrapper}>
                        <div className={styles.formContainer}>
                            <div className={styles.formTitle}>
                                <h2>
                                    Book Now
                                </h2>
                            </div>
                            <div className={styles.eachInput}>
                                <label>Your Full Name</label>
                                <input placeholder="Full name.."
                                    onChange={(e) => setFullName(e.target.value)}
                                    value={fullName}
                                />
                            </div>
                            <div className={styles.eachInput}>
                                <label>Please leave your e-mail</label>
                                <input placeholder="E-mail.."
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            <div className={styles.consultingFor}>
                                <label>Consulting for</label>
                                <div className={styles.consultingForWrapper}>
                                    <button className={selectedConsultedFor.includes('Skin') ? styles.eachConsultingSelected : styles.eachConsulting}
                                        onClick={() => selectConsultedForHandler('Skin')}
                                    >
                                        Skin
                                    </button>
                                    <button className={selectedConsultedFor.includes('Hair') ? styles.eachConsultingSelected : styles.eachConsulting}
                                        onClick={() => selectConsultedForHandler('Hair')}
                                    >
                                        Hair
                                    </button>
                                    <button className={selectedConsultedFor.includes('Therapies') ? styles.eachConsultingSelected : styles.eachConsulting}
                                        onClick={() => selectConsultedForHandler('Therapies')}
                                    >
                                        Therapies
                                    </button>
                                </div>
                            </div>
                            <div className={styles.eachInput}>
                                <label>Phone number</label>
                                <input type="number" placeholder="Please enter 10 digit phone number..." 
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </div>
                            <div className={styles.actionBtnWrapper}>
                                <div>
                                    <button className={isDisabled ? styles.submitBtnDisabled : styles.submitBtn}
                                        onClick={() => submitConsultationRequest()}
                                    >
                                        Submit
                                    </button>
                                </div>
                                <div className={styles.callNowBtn}>
                                    <a href="tel:+91-8929460356">Call Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookPreBridalConsultation
import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../Home/IntroducingBridalMakeups.module.css'

const IntroducingBridalMakeups = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.leftContainer}>
                        <div className={styles.introducing}>
                            Introducing
                        </div>
                        <div className={styles.lokaciBridalMakeup}>
                            <h3>Lokaci</h3>
                            <h3>
                                Bridal Makeups
                            </h3>
                        </div>
                        <div className={styles.description}>
                            <div>
                                Now get rid of sorting & filtering of vendors from unreliable sources.
                            </div>
                            <div>
                                We understand the value your special Day.
                                Hence, we have got the best trained / professional artists for you!
                            </div>

                        </div>
                        <div className={styles.exploreBtnWrapper}>
                            <Link to="bridal-makeup" className={styles.exploreBtn}>
                                Explore Now
                            </Link>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.cardWrapper}>
                            <Link to="bridal-makeup" className={styles.eachCard}>
                                <div className={styles.cardUpper}>
                                    {/* <img src="https://blog.venuelook.com/wp-content/uploads/2021/03/High-Shine-e1614842704143.jpg" /> */}
                                    <video
                                        loop={true}
                                        muted={true}
                                        autoPlay={true}
                                        src="https://lokaci.s3.ap-south-1.amazonaws.com/home-v1.mp4"
                                        // src="https://res.cloudinary.com/drotwjbzs/video/upload/v1667382850/Final_Comp_w7oxwk.mp4"
                                    />
                                </div>
                                <div className={styles.cardLower}>
                                    <div className={styles.cardTitle}>
                                        Bridal Makeup
                                    </div>
                                    <div className={styles.cardSubTitle}>
                                        Book anything from tradition bridal makeup look to the most trending makeup looks.
                                    </div>
                                </div>
                            </Link>
                            <Link to="pre-bridal-package" className={styles.eachCard}>
                                <div className={styles.cardUpper}>
                                    {/* <img src="https://rejove.com/wp-content/uploads/2019/06/skin-treatments.jpg" /> */}
                                    <video
                                        loop={true}
                                        muted={true}
                                        autoPlay={true}
                                        // src="https://res.cloudinary.com/drotwjbzs/video/upload/v1667388255/Final_Comp_oyxz1r.mp4"
                                        src="https://lokaci.s3.ap-south-1.amazonaws.com/home-v2.mp4"
                                    />
                                </div>
                                <div className={styles.cardLower}>
                                    <div className={styles.cardTitle}>
                                        Pre-bridal Packages
                                    </div>
                                    <div className={styles.cardSubTitle}>
                                        We do care about your skin to look flawless as well as your hairs.
                                    </div>
                                </div>
                            </Link>
                            <Link to="bridal-makeup" className={styles.eachCard}>
                                <div className={styles.cardUpper}>
                                    <img src="https://lokaci.s3.ap-south-1.amazonaws.com/family-makeup.jpg" />
                                </div>
                                <div className={styles.cardLower}>
                                    <div className={styles.cardTitle}>
                                        Family Makeup
                                    </div>
                                    <div className={styles.cardSubTitle}>
                                        Family and friends are hidden treasures, we help you create memories with them.
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default IntroducingBridalMakeups
import logo from './logo.svg';
import './App.css';
import LokaciNavigator from './MainNavigator/LokaciNavigator';

import ReactGA from 'react-ga';


// ReactGA.initialize('AW-761375362');
ReactGA.initialize('UA-158965233-1');


function App() {
  return (
    <LokaciNavigator />
  );
}

export default App;

import React from 'react'
import { Routes, Route } from "react-router-dom";
import About from '../Pages/AboutLokaci/About';
import Home from '../Pages/Home';
import BookDemoForm from '../Pages/Makeups/BookDemoForm';
import BridalMakeups from '../Pages/Makeups/BridalMakeups';
import PreBridalPackages from '../Pages/Makeups/PreBridalPackages';
import Error404 from '../Pages/PageNotFound/Error404';
import PrivacyPolicy from '../Pages/PrivacyPolicy/PrivacyPolicy';
import GlobalSearch from '../Pages/SearchBar/GlobalSearch';
import TermsOfUse from '../Pages/TermsOfUse/TermsOfUse';

const LokaciNavigator = () => {
    return (
        <Routes>
            <Route path="*" element={<Error404 />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/search" element={<GlobalSearch />}></Route>
            <Route path="/bridal-makeup" element={<BridalMakeups />}></Route>
            <Route path="/bridal-makeup/book-demo/:identifier" element={<BookDemoForm />}></Route>
            <Route path="/pre-bridal-package" element={<PreBridalPackages />}></Route>
            <Route path="/privacy" element={<PrivacyPolicy />}></Route>
            <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
            <Route path="/about" element={<About />}></Route>
        </Routes>
    )
}

export default LokaciNavigator
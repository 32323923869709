import React, { useState } from 'react'
import styles from '../BridalMakeups/TopMakeupLooks.module.css'

const TopMakeupLooks = () => {
    const [eachColData, setEachColData] = useState(
        [
            [
                {
                    "caption": 'Nude Bridal Look',
                    "navigateTo": 'Nude Bridal Look',
                },
                {
                    "caption": 'South Indian Bridal Look',
                    "navigateTo": 'Nude Bridal Look',
                },
                {
                    "caption": 'Arabic Bridal Look',
                    "navigateTo": 'Nude Bridal Look',
                },
                {
                    "caption": 'Christian Bridal Look',
                    "navigateTo": 'Nude Bridal Look',
                },
                {
                    "caption": 'Marathi Bridal Look',
                    "navigateTo": 'Nude Bridal Look',
                },
            ],
            [
                {
                    "caption" : "Bengali Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
                {
                    "caption" : "Celebrity Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
                {
                    "caption" : "Signature Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
                {
                    "caption" : "HD Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
                {
                    "caption" : "HD + Airbrush Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
            ],
            [
                {
                    "caption" : "Muslim Bridal Makeup",
                    "navigateTo" : "stylelink.in"
                },
                {
                    "caption" : "Classic Bridal Makeup",
                    "navigateTo" : "stylelink.in"
                },
                {
                    "caption" : "Reception Makeup",
                    "navigateTo" : "stylelink.in"
                },
                {
                    "caption" : "Cocktail Makeup",
                    "navigateTo" : "stylelink.in"
                },
                {
                    "caption" : "Sangeet Makeup",
                    "navigateTo" : "stylelink.in"
                },
            ]
        ]
    )


    const [topSearchAreaWise, setTopSearchAreaWise] = useState(
        [
            [
                {
                    "caption": 'Destination Wedding Makeup',
                    "navigateTo": 'Nude Bridal Look',
                },
                {
                    "caption": 'Bronzy Glow Makeup Look',
                    "navigateTo": 'Nude Bridal Look',
                },
                {
                    "caption": 'Silicon Makeup Look',
                    "navigateTo": 'Nude Bridal Look',
                },
                {
                    "caption": 'Maharashtrian Look',
                    "navigateTo": 'Nude Bridal Look',
                },
            ],
            [
                {
                    "caption" : "Smokey Eye Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
                {
                    "caption" : "Royal Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
                {
                    "caption" : "Rajasthani Bridal Makeup",
                    "navigateTo" : "lokaci.com"
                },
            ],
            [
                {
                    "caption" : "Soft And Dewy Makeup",
                    "navigateTo" : "stylelink.in"
                },
                {
                    "caption" : "Winged Eyeliner Eye Makeup",
                    "navigateTo" : "stylelink.in"
                },
                {
                    "caption" : "Water proof bridal Makeup",
                    "navigateTo" : "stylelink.in"
                },
            ]
        ]
    )



    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainHeader}>
                Top Searches For Makeup this season
            </div>
            <div className={styles.mainContent}>
                {eachColData.map((item, index) => (
                    <div key={index+"EP"}>
                        {item.map((itemPrintable, indexPrintable) => (
                            <div className={styles.eachPrintableItem}
                                key={indexPrintable+"EPI"}
                            >
                                {itemPrintable.caption}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div className={styles.mainHeader}>
                New Makeup in this season
            </div>
            <div className={styles.mainContent}>
                {topSearchAreaWise.map((item, index) => (
                    <div key={index+"P"}>
                        {item.map((itemPrintable, indexPrintable) => (
                            <div className={styles.eachPrintableItem}
                                key={indexPrintable+"PI"}
                            >
                                {itemPrintable.caption}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TopMakeupLooks
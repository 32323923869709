import React from 'react'
import styles from '../Makeups/BookDemoForm.module.css'
import { useParams } from 'react-router';

const BookDemoForm = () => {
    let params = useParams();
    const url = params.identifier;

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                <div className={styles.fieldsWrapper}>

                    <div className={styles.eachInputField}>
                        <div className={styles.eifTitle}>Full Name</div>
                        <div className={styles.eifField}>
                            <input placeholder="Please enter full name..." />
                        </div>
                    </div>

                    <div className={styles.eachInputField}>
                        <div className={styles.eifTitle}>City / Town / Area</div>
                        <div className={styles.eifField}>
                            <input placeholder="Please enter your city e.g. Noida, ..." />
                        </div>
                    </div>

                    <div className={styles.eachInputField}>
                        <div className={styles.eifTitle}>Phone</div>
                        <div className={styles.eifField}>
                            <input placeholder="Please enter 10 digit phone number..." 
                                type="number"
                            />
                        </div>
                    </div>

                    <div className={styles.eachInputField}>
                        <div className={styles.eifTitle}>OTP</div>
                        <div className={styles.eifField}>
                            <input placeholder="Please enter OTP received..." 
                                type="number"
                            />
                        </div>
                    </div>

                    <div className={styles.eachInputField}>
                        <div className={styles.eifTitle}>Pincode</div>
                        <div className={styles.eifField}>
                            <input placeholder="Please enter your area pincode..." 
                                type="number"
                            />
                        </div>
                    </div>

                    <div className={styles.eachInputField}>
                        <div className={styles.eifTitle}>Date of Function</div>
                        <div className={styles.eifField}>
                            <input placeholder="Please enter your area pincode..." 
                                type="date"
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BookDemoForm
import React, { useEffect, useRef, useState } from 'react'
import WhatNext from '../../Components/Content/WhatNext'
import MainFooter from '../../Components/Footer/MainFooter'
import AtHome from '../../Components/Headers/AtHome'
import styles from '../AboutLokaci/About.module.css'

import ReactGA from 'react-ga';

const About = () => {
    const ref = useRef()

    const [aboutUsStats, setAboutUsStats] = useState([
        {
            "title": 'Launched',
            "number": '2018'
        },
        {
            "title": 'Salons',
            "number": '1600+',
        },
        {
            "title": 'Barbers/stylist',
            "number": '23000+'
        },
        {
            "title": 'Bookings Delivered',
            "number": '400000+'
        },
        {
            "title": 'Review Images',
            "number": '12500+'
        },
        {
            "title": 'Play Store Rating',
            "number": '4.8'
        },

    ])

    var offerIndex = 0;
    useEffect(() => {
        offerSlider();

        ReactGA.pageview(window.location.pathname);
    }, [])

    function offerSlider() {
        var j;
        // var offerslides = document.getElementsByClassName("ofslidsP");
        var offerslides = ref.current.children;
        for (j = 0; j < offerslides.length; j++) {
            offerslides[j].style.display = "none";
        }
        offerIndex++;
        if (offerIndex > offerslides.length) {
            offerIndex = 1
        }
        offerslides[offerIndex - 1].style.display = "block";
        offerslides[offerIndex - 1].style.animation = "ofkeanimation2 .5s forwards";
        setTimeout(offerSlider, 3000);
    }



    return (
        <div className={styles.mainWrapper}>
            <AtHome />
            <div className={styles.backdrop}></div>
            <div className={styles.mainContainer}>
                <div className={styles.contentWrapper}>

                    <div className={styles.mainHeader}>
                        <div className={styles.title}>
                            <h2>
                                About Lokaci
                            </h2>
                            <div className={styles.mainBigTitle}>
                                <div className={styles.offSliders} ref={ref}>
                                    <div className={styles.ofslidsP} >Re-defining</div>
                                    <div className={styles.ofslidsP} >Re-inventing</div>
                                    <div className={styles.ofslidsP} >Re-creating</div>
                                </div>
                                <div className={styles.subCaption}>
                                    &nbsp;the ultimate salon experience.
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={styles.mainContent}>
                        <h2>
                            OUR MISSION
                        </h2>
                        <p>
                            Lokaci is a platform to empower entrepreneurs and salons by providing a full stack technology that increases earnings and eases day-to-day operations.
                            Bringing hygienic, affordable, and trusted salon services that users can get without waiting in salons.
                            India’s first real time salon appointment platform to book hair and beauty services like hair-spa, shave, keratin, smoothening, pedicure, makeup from your favorite at a beauty parlor or salon near you.
                            LOKACI is an Indian online service providing company, the company provides home as well as onsite services of its prior booking appointments pertaining to hair-dressing and makeups for women, men and kids. We are currently working in Delhi city, we have immense number of options to choose customers’ type of hair-styles/nail arts/makeups. We provide a platform for the Salons/Spas/Beauty parlors to offer their services and for the customers to consume them. We are link between the Customers and vendors. The LOKACI works under the patronage of “LOKACI INNOVATIONS AND TECHNOLOGY”.
                        </p>
                    </div>

                </div>


            </div>
                <WhatNext />
            <div className={styles.statsWrapper}>
                {aboutUsStats.map((item, index) => (
                    <div className={styles.eachStats}>
                        <div className={styles.statsVal}>{item.number}</div>
                        <div className={styles.statsTitle}>{item.title}</div>
                    </div>
                ))}
            </div>


            <MainFooter
            />

        </div>
    )
}

export default About
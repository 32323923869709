import React from 'react'
import AtHome from '../../Components/Headers/AtHome'
import styles from '../PrivacyPolicy/PrivacyPolicy.module.css'

const PrivacyPolicy = () => {
    return (
        <div className={styles.mainWrapper}>
            <AtHome />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div>
                        <h1> Privacy Policy </h1>
                        <p>We view protection of your privacy as a very important principle. We understand clearly
                            that you and your Personal Information is one of our most important assets. We store
                            and process your Information including any sensitive financial information collected as
                            defined under the Information Technology Act, 2000 and Rules there under. We may
                            share personal information with our other corporate entities and affiliates.
                        </p>
                        <p>Please note that we may disclose information about you to third parties or government
                            authorities if we believe that such a disclosure is reasonably necessary to:
                            1. take actions regarding suspected illegal activities;
                            2. enforce or apply our Terms and Conditions;
                            3. comply with legal process or other government inquiry, such as a search warrant,
                            subpoena, statute, judicial proceeding, or other legal process/notice served on us;
                            4. protect our rights, reputation, and property, or that of our users, affiliates, or the
                            general public.
                        </p>
                        <p>We and our affiliates will share/sell some or all of your personal information with another
                            business entity should we (or our assets) plan to merge with, or be acquired by that
                            business entity, or re-organization, amalgamation, restructuring of business. We have
                            developed this Privacy Policy to protect Your personal/financial information and keep it
                            confidential. By visiting Our LOKACI platform, you are accepting and consenting to the
                            practices described in this Privacy Policies. The following Privacy Policy and Cookie Policy
                            outlines the information we may process and how we may use that information to better
                            serve you while using our LOKACI platform.
                        </p>
                        <p>Third Party. At times we may tie-up with third parties, brand owners or other partners
                            and make available certain offers, incentives or events or special schemes. In such
                            instances, your personal information may be shared with such third parties and/or may
                            become available to them or be disclosed to them, such third parties may have their own
                            applicable privacy rules and We shall not be liable for the use or misuse of Your
                            information by such third parties.
                        </p>
                        <p><i>Note: We only collect your Personal Information to conduct our business and to enable us to
                            deliver and improve our services. We do <b> not </b> for any reason whatsoever sell your Correspondence Information to any third party.</i></p>

                        <h4>Lokaci collects:</h4>

                        <h4>Data provided by users to Lokaci, such as during account creation, validating account's login/authentication</h4>
                        <h4>Data created during use of our services, such as location, app usage, and device data</h4>
                        <h4>Data from other sources, such as Lokaci partners</h4>

                        <ol>
                            <li>
                                <h4>Personal Data</h4>
                                Personal Data we may collect from you are as under:
                                1. Personal details (e.g.name, contact details including, residential address, date of birth, documents such as identity card / passport details / Aadhaar details / PAN / Voter ID / driving license, and/or education details) provided by you to us to avail various products/services from us.
                                2. Your details including transaction history, balances, payment details, for effecting transfer of monies through various payment channels provided by us.
                                3. financial details (e.g.income, expenses, and/or credit history) needed as part of request for some of our products/services;
                                4. images of documents/ photos required to avail any of our products/services.
                                5. voice recordings of our conversations with our customer care agent with you to address your queries/grievances;
                                6. employment details (e.g.occupation, positions held, employment history, salary and/or benefits) as part of our record retention for credit/various product evaluations or required under applicable law including Prevention of Money Laundering (Maintenance of Records) Rules, 2005
                                7. specimen signature(s) for processing of your instructions received by us through our various payment and delivery channels;
                                8. opinions provided by you to us by way of feedback or responses to surveys;
                                9. information obtained from your mobile device by way of using our app like device location, communication information including contacts and call logs, device information (including storage, model, mobile network), transactional and promotional SMS/app notifications.
                            </li>

                            <li>
                                <h4>Information supplied by users</h4>
                                To avail certain Services on the Lokaci App, Users are required to provide certain personal information for the registration process which may include:
                                a) their name, b) email address, c) phone number, d) business entity information, (e) account number of the Users, (f) KYC details and any other such information as required.The Information as supplied by the Users enables us to provide the Services and improve the Lokaci App Services and provide you the most user-friendly experience.In some cases / provision of certain service(s) or utility(ies), we may require your contact address as well.Further, upon downloading the App, Users are requested to share access to their contact list (as stored on their mobile device on which the App is downloaded), so as to enable the User to share recent offers/gift cards/packages updates with his customers (who might be or might not be Users).This contact list of the User is uploaded to <a href="https://lokaci.com/webservices/">https://lokaci.com/webservices/</a>, for the purposes of improving and enhancing the User experience of the App. In an attempt to improve our Services, we may, from time to time, collect any information (other than what is mentioned above) from Users through App / Website. You may be assured that we do not share this data with any third party except in accordance with the terms of this Policy.
                                All Information is service dependent and we may use the Information to provide the Services, maintain, protect, and improve the Lokaci App Services, and for developing new services.
                                We may also use your email address and phone number without further consent for marketing, non-marketing or administrative purposes (such as notifying you of major changes, for customer service purposes, providing information about updates to Lokaci App Services, billing, etc.).
                                Any Information provided by you will not be considered as personal information if it is freely available and/or accessible in the public domain or is not deemed as a personal information under the applicable law.Further, any reviews, comments, messages, blogs posted / uploaded / conveyed / communicated by Users on the public sections of the Site or an application store (like the App Store or Play Store) becomes published content and is not considered as personal information which is subject to this Policy.
                                In case you choose to decline to submit Information on Lokaci App/Website, we may not be able to provide certain services on the App/Webite to you.We will make reasonable efforts to notify you of the same at the appropriate time.In any case, we will not be liable and or responsible for the denial of certain services to you for due to not receiving necessary Information from you.
                                When you register with the Lokaci App Services, we may (at our discretion) contact you from time to time about updation of your Information.

                                <h3 >How do we use this information?</h3>
                                <div>
                                    We use the information that we have (subject to choices you make) as described below, and to provide and support the Lokaci Products and related services described in the Lokaci Terms and Conditions.Here's how:
                                    Provide, personalise and improve our Products.
                                    We use the information we have to deliver our Products, including to personalise features and content (including recently trending stylist in your area, List Feed, offers and ads) and make suggestions for you (such as groups or events that you may be interested in or stylists/salons/trends/users that you may want to follow) on and off Lokaci.To create personalised Products that are unique and relevant to you, we use your contacts, preferences, interests and activities based on the data that we collect and learn from you and others (including any data with special protections you choose to provide) while you explore services on Lokaci App.We map you contact informations to validate your contact details to authenticate for our Products &amp; to verify the right user of your Lokaci Accounts.
                                    Information across Lokaci Products and devices: We connect information about your activities on different Lokaci Products and devices to provide a more tailored and consistent experience on all Lokaci Products that you use, wherever you use them.For example, we can suggest that you make a booking on Lokaci that includes people you follow on Lokaci.We can also make your experience more seamless, for example, by automatically filling in your registration information (such as your phone number) from one Lokaci Product when you sign up for an account on a different Product.
                                    Location-related information: We use location-related information – such as your current location, where you live, the places you like to go, and the businesses and people you're near – to provide, personalise and improve our Products, including ads, for you and others. Location-related information can be based on things such as precise device location (if you've allowed us to collect it), IP addresses and information from your and others' use of Lokaci Products (such as check-ins or events you attend).
                                    <h4>Product research and development: </h4> We use the information we have to develop, test and improve our Products, including by conducting surveys and research, and testing and troubleshooting new products and features.

                                    <h4>Provide measurement, analytics and other business services.</h4>
                                    We use the information we have (including your activity off our Products, such as the websites you visit and ads you see) to help advertisers and other partners measure the effectiveness and distribution of their ads and services, and understand the types of people who use their services and how people interact with their websites, apps and services.Learn how we share information with these partners.
                                    <h4>Promote safety, integrity and security.</h4>
                                    We use the information that we have to verify accounts and activity, combat harmful conduct, detect and prevent spam and other bad experiences, maintain the integrity of our Products, and promote safety and security on and off Lokaci Products.For example, we use data that we have to investigate suspicious activity or breaches of our Terms or Policies, or to detect when someone needs help.To learn more, visit the Lokaci Security Help Centre.
                                    <h4>Communicate with you.</h4>
                                    We use the information that we have to send you marketing communications, communicate with you about our Products and let you know about our Policies and Terms.We also use your information to respond to you when you contact us.
                                    Research and innovate for social good.
                                    We use the information we have (including from research partners we collaborate with) to conduct and support research and innovation on topics of general social welfare, technological advancement, public interest, health and well-being.For example, we analyse information that we have about migration patterns during crises to aid relief efforts.Learn more about our research programmes.
                                </div>
                            </li>
                            <li>
                                <h4>Sharing of your Personal Data</h4>
                                Any Personal Data that we have access to shall never be shared.
                                In various processes / submission of applications / availment of product/service offerings, we even seek your explicit consent to use / share your Personal Data.
                                In our business and operational processes, we only share the data on a partial and “need-to-know” basis to designated personnel or partners or service providers.
                                We will share your data with competent/ legal/statutory/regulatory agencies / authorities or partners/service providers acting on our behalf (as the case may be) in following cases:
                                1. only for enabling the provision of the products/services availed by you, strictly on a “need to know” basis and subject to applicable laws.
                                2. it is directed or required by legal/regulatory / statutory / governmental authorities under applicable laws/regulations though a legally obligated request.
                                3. it is required by financial institutions to verify, mitigate or prevent fraud or to manage risk or recover funds in accordance with applicable laws/regulations.
                            </li>
                            <li>
                                <h4>Usage of Your Personal Data</h4>
                                We use your Personal Data in our business activities for providing our or our partners’ products/services and to perform, among other actions, the following:
                                1. to facilitate the transactions or report on these transactions;
                                2. to undertake research and analytics for offering or improving our products/services and their security and service quality;
                                3. to check and process your requirements submitted to us for products/services and/or instructions or requests received from you in respect of these products/services;
                                4. to share with you, updates on changes to the products/services and their terms and conditions;
                                5. to take up or investigate any complaints/claims/disputes;
                                6. to respond to your queries or feedback submitted by you;
                                7. to verify your identity for us to provide products/services to you;
                                8. to carry credit checks, screenings or due diligence checks as lawfully required by us;
                                9. to monitor and review products/services from time to time;
                                10. to undertake financial/regulatory/management reporting, and create and maintain various risk management models;
                                11. for conducting audits and for record keeping purposes;
                                12. for selective offers and promotions.
                                External processing: We may provide your personal information to our affiliates or other trusted businesses or persons or service providers engaged by us, or institutions that we partner with to assist us with providing you with products/services to better serve your needs and interests, based on your instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.
                                We also use your Personal Data to fulfill the requirements of applicable laws/regulations and/or court orders/regulatory directives received by us.
                            </li>
                            <li>
                                <h4>Purging of your Personal Data</h4>
                                1. You may delete your Lokaci account at any point of time by making such choice in the Lokaci’s desktop website, mobile WAP site or mobile application.With this we will no longer provide your data for external processing as mentioned above.However, we retain your Personal Data as long as the purpose for its usage exists, after which the same is disposed off by us except for any record retention required as per applicable law.
                                2. The provisions of various laws require your transaction logs to be stored for longer periods post the deletion of an account.Further, in the event of the pendency of any legal/regulatory proceeding or receipt of any legal and/or regulatory direction to that effect, we may be required by the law of the land to retain your Personal Data for longer periods.
                                <div class="how-we-use">
                                    <h5>Transparency &amp; Choices for users</h5>
                                    <p>We enable our users to access and control the data that we collects, from their device: </p>
                                    <ul>
                                        <li>Device permissions</li>
                                        <li>In-app settings</li>
                                        <li>In-app ratings pages</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <h4>V.Cookie Policy</h4>
                                1. Please note that a "cookie" is a small piece of information stored by a web server on a web browser so it can be later read back from that browser.
                                2. We may use cookie and tracking technology depending on the features offered.
                                3. No Personal Data will be collected via cookies and other tracking technology; however, if you previously provided Personal Data, cookies may be tied to such information.
                            </li>
                            <li>
                                <h4>Links to other websites</h4>
                                Our website may contain links to other websites which are not maintaintained by us.This privacy policy only applies to us.You are requested to read the other websites’ privacy policies when visiting these websites.
                            </li>
                            <li>
                                <h4>Reasonable Security Practices and Procedures</h4>
                                We take various steps and measures to protect the security of your Personal Data from misuse, loss, unauthorised access, modification or disclosure.We use latest secure server layers encryption and access control on our systems.Our safety and security processes are audited by a third party cyber security audit agency from time to time.
                                We have provided multiple levels of security to safeguard your app by Login/Logout option and AppLock feature that can be enabled by you.We also ensure the device binding so that the same login cannot be used on different device without any additional authentication/OTP.Please do not share your Lokaci login, password and OTP details with anybody.
                            </li>
                            <li>
                                <h4>Contact us</h4>
                                You may contact us on any aspect of this policy or for any discrepancies/grievances with respect to your Personal Data, by writing to our Grievance Officer at Lokaci Private Ltd., B 61, Sector 2, Noida – 201301.
                            </li>
                            <li>
                                <h4>Policy Review &amp; Updates</h4>
                                This policy will be reviewed by us as and when required and the same may change at any time.The latest &amp; most updated policy can always be found at https://lokaci.com/privacy
                                While we will make reasonable efforts to keep you posted on any updates to this privacy policy, to make sure that you are aware of any changes, we recommend that you review this policy periodically.
                                This Privacy Policy is applicable uniformly to Lokaci’s desktop website, mobile WAP site &amp; mobile applications
                                In this policy, the words “we”, “ours” and/or “us” refer to Lokaci Private Limited and “you” and/or “your” refer to our customers/salon partners/users/merchants.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
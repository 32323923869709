import React from 'react'
import styles from '../Gallery/EachPortraitFrame.module.css'

const EachPortraitFrame = props => {
    let type = props.type;
    let source = props.source;
    if (type === "landscape") {
        return (
            <div className={styles.mainContainerL}>
                <div className={styles.flipCardInner}>
                    <div className={styles.flipCardFront}>
                        <img src={source} alt="bridal-image-for-wedding-by-lokaci" />
                    </div>
                    <div className={styles.flipCardBack}>
                        {/* Hey */}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.mainContainer}>
                <div className={styles.flipCardInner}>
                    <div className={styles.flipCardFront}>
                        <img src={source} alt="bridal-image-for-wedding-by-lokaci" />
                    </div>
                    <div className={styles.flipCardBack}>
                        {/* Hey */}
                    </div>
                </div>
            </div>
        )
    }
}

export default EachPortraitFrame
import React from 'react'
import styles from '../Content/WhatNext.module.css'

const WhatNext = () => {
    return (
        <div className={styles.whatNextWrapper}>
            <div>
                <div className={styles.wnhTitle}>
                    Organizing one of the most difficult and un-organized industry!
                </div>
                <div>
                    What's Next?
                </div>
                <div className={styles.wnhVal}>
                    Continuously enhancing the technology, and launching the most deeply researched / data backed application to grow salon business.
                </div>
                <div className={styles.buildingItForFuture}>
                    Building it for Future!
                </div>
            </div>
        </div>
    )
}

export default WhatNext
import React, { useState } from 'react'
import styles from '../Gallery/PhotoGallery.module.css'
import EachPortraitFrame from './EachPortraitFrame'

const PhotoGallery = () => {
    const [imageArr, setImageArr] = useState([
        [
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455826/Lokaci-web-looks-gallery/IMG_2108_360x540_jnkns5.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455827/Lokaci-web-looks-gallery/IMG_2608_360x360_yxhqmu.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455826/Lokaci-web-looks-gallery/IMG_2201_360x360_ja1ys0.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455827/Lokaci-web-looks-gallery/IMG_2713_360x540_noxllm.webp",
        ],
        [
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455827/Lokaci-web-looks-gallery/IMG_2808_360x540_td9kfu.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455828/Lokaci-web-looks-gallery/IMG_5023-360x360_f82sin.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455828/Lokaci-web-looks-gallery/IMG_5551_360x360_lc0l64.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455827/Lokaci-web-looks-gallery/IMG_5174_360x540_ggpovy.webp",
        ],
        [
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455828/Lokaci-web-looks-gallery/IMG_4918-540x360_ukpo6z.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455829/Lokaci-web-looks-gallery/IMG_6191_360x360_wmtcyt.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667456939/Lokaci-web-looks-gallery/bridal-makeup-portfolio-28_z0yjiw.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455826/Lokaci-web-looks-gallery/IMG_1809_360x360_bwdvbq.webp",
        ],
        [
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455828/Lokaci-web-looks-gallery/IMG_5405_540x360_buf8yo.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667456939/Lokaci-web-looks-gallery/random01_360x560_wr5tyz.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455826/Lokaci-web-looks-gallery/IMG_1809_360x360_bwdvbq.webp",
            "https://res.cloudinary.com/drotwjbzs/image/upload/v1667455828/Lokaci-web-looks-gallery/IMG_5860_360x540_i5whnh.webp",
        ],
    ]
    )
    // const [imageArr, setImageArr] = useState([
    //     [
    //         "https://blog.venuelook.com/wp-content/uploads/2021/09/hd-makeup-640x870.jpg",
    //         "https://bridalmakeupartistnearme.com/wp-content/uploads/2021/04/perfect-bridal-makeup-artist.jpg",
    //         "https://images.shaadisaga.com/shaadisaga_production/photos/pictures/001/379/999/original/bridalmakeup2.jpg",
    //         "https://c0.wallpaperflare.com/preview/270/228/999/beads-beautiful-blurred-background-bridal-gown.jpg"
    //     ],
    //     [
    //         "https://i0.wp.com/shaadiwish.com/blog/wp-content/uploads/2021/11/bridal-portrait-2.jpg",
    //         "https://www.weddingsutra.com/images/bride/types-bridal-makeup/types-bridal-makeup-thumbnail.jpg",
    //         "https://cdn0.weddingwire.in/article/3631/3_2/960/jpg/11363-bridal-makeup-artists-cupcake-productions-lead-image.jpeg",
    //         "https://www.shaadidukaan.com/vogue/wp-content/uploads/2020/03/Kerala-Bridal-Makeup-Look.jpg",
    //     ],
    //     [
    //         "https://www.rajumehandiart.com/images/bridal-makeup/bridal-makeup-1.jpg",
    //         "https://cpimg.tistatic.com/06235045/b/4/Wedding-Bridal-Makeup-Services.jpg",
    //         "https://i0.wp.com/shaadiwish.com/blog/wp-content/uploads/2021/11/bridal-portrait-2.jpg",
    //         "https://de927adv5b23k.cloudfront.net/wp-content/uploads/2019/03/26110318/Wheatish-Light-bridal-makeup-Meherakolahofficial.jpg",
    //     ],
    //     [
    //         "https://blog.venuelook.com/wp-content/uploads/2021/03/High-Shine-e1614842704143.jpg",
    //         "https://bridalmakeupartistnearme.com/wp-content/uploads/2020/05/bridal-makeup-portfolio-28.jpg",
    //         "https://i.pinimg.com/736x/ef/04/51/ef045108a07635fca506e5645cf63264.jpg",
    //         "https://i0.wp.com/shaadiwish.com/blog/wp-content/uploads/2021/11/bridal-portrait-2.jpg",
    //     ],
    // ]
    // )

    return (
        <div>
            <div className={styles.galleryContainer}>
                {imageArr.map((itemImg, index) => (
                    <div key={index} className={styles.galleryContent}>
                        <div className={styles.eachGroup}>
                            {itemImg.slice(0, 2).map((itemPic, indexPic) => (
                                <EachPortraitFrame type={indexPic % 2 && "landscape"} key={indexPic}
                                    source={itemPic}
                                />
                            ))}
                        </div>
                        <div className={styles.eachGroup}>
                            {itemImg.slice(2, 4).map((itemPicSec, indexPicSec) => (
                                <EachPortraitFrame type={indexPicSec % 2 ? "" : "landscape"} key={indexPicSec}
                                    source={itemPicSec}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.galleryFooter}>
                {/* <button>Show more</button> */}
            </div>
        </div>
    )
}

export default PhotoGallery
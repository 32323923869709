import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styles from '../Headers/AtBridalMakeups.module.css'


const AtBridalMakeups = props => {
    const [menuData, setMenuData] = useState([
        {
            "title" : 'Pre-Bridal Packages',
            "navigateTo" : '/pre-bridal-package'
        },
        // {
        //     "title" : 'Mehandi',
        //     "navigateTo" : ''
        // }
    ])

    const onCallRequest = () => {
        document.getElementById("sendQueryOnBridal").click();
    }
    return (
        <div className={styles.headerTransWrapper}>
            <div className={styles.htwLeft}>
                <Link to="/" className={styles.mainLogo}>
                    <img
                        src="https://lokaci.s3.ap-south-1.amazonaws.com/lokaci_logo_white.png" />
                </Link>
            </div>
            <div className={styles.htwCenter}>
                <div className={styles.menuWrapper}>
                    {menuData.map((item, index) => (
                        <Link to={item.navigateTo} className={styles.eachMenu}
                            key={index+"SUPER"}
                        >
                            {item.title}
                        </Link>
                    ))}
                </div>
            </div>

            <div className={styles.htwRight}>
                <button className={styles.loginBtn} href="https://lokaci.com/app"
                    onClick={() => onCallRequest()}
                >
                    Request A Call
                </button>
            </div>

        </div>
    )
}

export default AtBridalMakeups
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from '../Headers/AtHome.module.css'



const AtHome = props => {
    const [menuData, setMenuData] = useState([
        {
            "title": 'About Us',
            "navigateTo": '/about',
            "icon": ''
        },
        {
            "title": 'Make-ups',
            "navigateTo": '/bridal-makeup',
            "icon": 'https://healthindiatpa.com/images/new.gif',
        },
        {
            "title": 'Pre-Bridal',
            "navigateTo": '/pre-bridal-package',
            "icon": '',
        },
    ])

    return (
        <div className={styles.headerTransWrapper}>
            <div className={styles.htwLeft}>
                <Link to='/' className={styles.mainLogo}>
                    <img
                        // src="https://res.cloudinary.com/drotwjbzs/image/upload/v1662725527/lokaci_logo_white_dndg2v.png" />
                        src="https://lokaci.s3.ap-south-1.amazonaws.com/lokaci_logo_white.png" />
                </Link>
            </div>
            <div className={styles.htwCenter}>
                <div className={styles.menuWrapper}>
                    {menuData.map((item, index) => (
                        <Link to={item.navigateTo} className={styles.eachMenu}>
                            {item.title}
                            {item.icon &&
                                <div className={styles.iconWrapper}>
                                    <img src={item.icon} />
                                </div>
                            }
                        </Link>
                    ))}
                </div>

            </div>

            <div className={styles.htwRight}>
                {/* <button className={styles.loginBtn} href="https://lokaci.com/app">
                    Login
                </button> */}
            </div>

        </div>
    )
}

export default AtHome
import React, { useEffect, useState } from 'react'
import styles from '../BridalMakeups/SendQuery.module.css'
import { FcCustomerSupport } from "react-icons/fc";
import Modals from '../UI/Modals';
import { BASE_URL_API } from '../../References/URLs';


const SendQuery = props => {
    const [isShow, setIsShow] = useState(false)
    const [phone, setPhone] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const [fullName, setFullName] = useState('');
    const [venue, setVenue] = useState('')
    const [weddingDate, setWeddingDate] = useState('');

    const modalStateHandler = k => {
        setIsShow(k);
    }

    const fullNameHandler = (k) => {
        setFullName(k)
    }

    const venueHandler = (k) => {
        setVenue(k)
    }

    const weddingDateHandler = k => {
        setWeddingDate(k)
    }

    const initiatePhoneVerificationHandler = (t) => {
        setIsShow(true);
    }

    const setPhoneHandler = txt => {
        setPhone(txt);
    }

    const phoneVerificationHandler = (k) => {
        setIsVerified(k);
        registerCallbackRequest();
    }

    const registerCallbackRequest = async () => {
        if(phone.length==10) {
            const registerBridalCallbackResponse = await fetch(BASE_URL_API+"/registerBridalDemoCallback",
            {
                method : "POST",
                body : JSON.stringify({
                    phone : phone,
                    fullName : fullName,
                    venue : venue,
                    weddingDate : weddingDate
                })
            })

            if(!registerBridalCallbackResponse.ok) {
                console.log("Something went wrong, server error");
            } else {
                const registerBridalCallbackRespo = await registerBridalCallbackResponse.json();
                console.log(registerBridalCallbackRespo);
            }
        } else {
            console.log("Invalid phone number");
        }
    }
    

    return (
        <div className={styles.sendQueryWrapper}>
            <div className={styles.sendQueryContainer}>
                <div className={styles.sendQueryContainerHeader}>
                    <h2>Book a demo call/query!</h2>
                    <h4>Book a demo visit or enquire over a call</h4>
                </div>
                <div className={styles.sendQueryContentWrapper}>
                    <div className={styles.sqcwOne}>
                        <div className={styles.sqcwoFir}>
                            <FcCustomerSupport size={70} />
                        </div>
                        <div className={styles.sqcwoSec}>
                            <div className={styles.sqcqoSecHeader}>
                                Enter your phone number to get a call back
                            </div>
                            <div className={styles.queryForm}>
                                <input placeholder="Enter 10 digit phone..."
                                    onFocus={(txt) => initiatePhoneVerificationHandler(txt.target.value)}
                                />
                                <button onClick={() => setIsShow(true)} id="sendQueryOnBridal" >Send Query</button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sqcwTwo}>
                        <div className={styles.sqcwtCaption}>
                            Connect us on whatsApp
                        </div>
                        <div className={styles.qr}>
                            <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1667457220/Lokaci-web-introdusing-container/bridal-demo-query_wkmgz6_lgg8fe.webp" />
                        </div>

                    </div>

                </div>
                <Modals
                    isOpen={isShow}
                    modalStateHandler={modalStateHandler}
                    phone={phone}
                    fullName={fullName}
                    venue={venue}
                    weddingDate={weddingDate}
                    setPhoneHandler={setPhoneHandler}
                    fullNameHandler={fullNameHandler}
                    venueHandler={venueHandler}
                    weddingDateHandler={weddingDateHandler}
                    phoneVerificationHandler={phoneVerificationHandler}
                />
            </div>
        </div>
    )
}

export default SendQuery
import React from 'react'
import AtHome from '../../Components/Headers/AtHome'
import styles from '../TermsOfUse/TermsOfUse.module.css'

const TermsOfUse = () => {
    return (
        <div className={styles.mainWrapper}>
            <AtHome />

            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <h1>Terms of Use of Lokaci</h1>
                                    <p >Welcome to LOKACI. This document is an electronic record in terms of Information Technology Act, 2000 and published in accordance with the provisions of Rule 3) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms and Conditions of Use for access or usage of LOKACI platform.</p>

                                    <p>Your use of the App/Website and the underlying services and tools offered through the App/Website are subject to and governed by the following terms and conditions including the applicable policies which are incorporated herein by way of reference. If you transact on the App/Website, you shall be subject to the policies that are applicable to the App for such transaction.</p>

                                    <p>USING OR OTHERWISE ACCESING THE APP/WEBSITE INDICATES YOUR AGREEMENT TO ALLTHE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS AND CONDITIONS CAREFULLY BEFORE PROCEDDING.</p>
                                </div>
                                <div id="introduction">
                                    <h2><b> 1. Introduction. </b></h2>
                                    <h5>1.1 Translation of the terms </h5>
                                    <div><p>⇒ LOKACI may provide a translation of the English version of the Terms into other languages. You understand and agree that any translation of the Terms into other languages is just only for your convenience and that the English version shall govern the terms of your relationship with LOKACI. Furthermore, if there are any inconsistencies between the English version of the Terms and its translated version, the English version of the Terms shall prevail over others.		</p></div>
                                    <h5>1.2 Definitions</h5>
                                    <p>⇒ For the purpose of these Terms and conditions, wherever the context so requires “YOU” or “employee” shall mean any natural or legal person who has agreed to become an employee/provider by using the App by providing Registration Data while registering on the App as Registered user. </p>
                                    <p>⇒ The terms “We”, “Us”, “Our” shall mean LOKACI.com </p>
                                    <h5>1.3 Use of another’s account </h5>
                                    <p>⇒ You may not manipulate identifiers in order to disguise the origin of nay message or transmittal you send to us on or through the App/Website or any service offered on or through the App/Website. You may not pretend that you are, or you represent, someone else, or impersonate any other individual or entity or access the App through another user’s account, unless you are authorized to do so. </p>
                                </div>
                                <div id="obligations">
                                    <h2><b>2. Obligations.</b></h2>
                                    <h5>2.1 Membership Eligibility</h5>
                                    <p>⇒ Only persons who can form legally binding contracts under Indian Contract Act, are permitted to use the App/Website. Any person who is “incompetent to contract” within the meaning of Indian Contract Act, including minors, un-discharged insolvents etc. are not eligible to use the App/Website.</p>
                                    <h5>2.2 Worker’s Eligibility </h5>
                                    <p>⇒ To use the services of LOKACI or enter into this contract, you hereby declare and warrant that:
                                        1. All the workers working with you are of the “Minimum Age” (described below) or older.
                                        2. You will only have one LOKACI account which must be in your real name.
                                        3. You are not already restricted by LOKACI from using the its services.
                                        4. You are fully able and competent to understand and agree the terms, conditions, obligations, affirmations, representations, and warranties set forth in this document “Terms and Conditions”.
                                        “Minimum Age” is the age described according to “The Child and Adolescent Labor (Prohibition and Regulation) Act of 1986” of India. </p>
                                    <p>⇒ Compliance with Laws. You are in compliance with all laws and regulations in the country in which you live when you access to the LOKACI website or App and use the its services.</p>
                                    <p>You agree to use the services only in compliance with these Terms and Conditions &amp; applicable law, and in such a manner that does not violate our legal rights or those of any third party/parties. </p>
                                    <h5>2.3 Responsibility for your Account </h5>
                                    <p>⇒ You will be solely responsible for the development, operation, and maintenance of your Account and for all materials that appear through your Account.</p>
                                    <p>⇒ If you use the LOKACI platform, you shall be responsible for maintaining the confidentiality of your Account and Password and you shall be responsible for all activities that occur under your Account and Password. You agree that if you provide any information that is untrue, inaccurate, not current or incomplete, we shall have the right to indefinitely suspend or terminate or block access of your membership on the LOKACI. </p>
                                    You agree to
                                    <p>1. immediately notify LOKACI of any unauthorized use / breach of your password or
                                        account, </p>
                                    <p>2. ensure that you exit from your account at the end of each session.</p>
                                    <p> What types of information are not allowed on LOKACI website / App through your
                                        account?</p>
                                    <p>1. Nudity or other sexually suggestive content
                                        2. Hate speech, credible threats or direct attacks on an individual or group
                                        3. Content that contains self-harm or excessive violence
                                        4. Fake or impostor profiles
                                        5. Spam</p>
                                    Note: However, as effective as encryption technology is, no security system is impenetrable. We
                                    cannot guarantee the security of our database, nor can we guarantee that information you
                                    supply won't be intercepted while being transmitted to us over the Internet. Any transmission of
                                    information by you to our LOKACI platform is at your own risk. We recommend that you do not
                                    disclose your password to anyone.

                                    <h2><b>3. Rights and Limits.</b></h2>
                                    <h5>3.1 Intellectual Property Rights</h5>
                                    ⇒ LOKACI reserves all of its intellectual property rights in the services. Providing the
                                    services to the LOKACI customers does not give you any ownership in our services or the
                                    content or information made available through our customers. Trademarks, logos, quote,
                                    images, videos used in advertisements on the website/App are be the trademarks of
                                    LOKACI.
                                    <h5>3.2 Price Modification Rights</h5>
                                    ⇒ LOKACI reserves its right to change or modify the price of any service(s), in the Website
                                    or in the App any time without any prior notice, however in compliance with all
                                    applicable laws. All payments made against the purchases of service(s) on Website by
                                    you shall be in the local currency of that country in which you live only. Website/App will
                                    not facilitate transaction with respect to any other form of currency with respect to the
                                    purchases/bookings made on Website/App.
                                    <h5>3.3 Limitations on services / bookings</h5>
                                     LOKACI reserves the right to limit your Services, including the number of your bookings
                                    and your ability to invite other customers. LOKACI reserves the right to restrict, suspend,
                                    or terminate your account if LOKACI believes that you may be in breach of this Contract
                                    or law or are misusing the Services (e.g. violating any Do’s and Don’ts).
                                    <h5>3.4 Limitations on sending messages to customers</h5>
                                     You agree that you will not send messages or make unwilled call to the customers:
                                    1. offering to make national or international money transfers for amounts exceeding
                                    the asking price of a service mentioned in the website / app, with intent to request a
                                    refund of any portion of the payment.
                                    2. once the services have been completed.
                                    <h5>3.5 No Discrimination</h5>
                                     Indian laws prohibit any preference, limitation or discrimination based on race, color,
                                    religion, sex, national origin, age, handicap or other protected class. It’s your sole
                                    responsibility to treat all the LOKACI customers impartially and provide services to them
                                    accordingly to their booking slots.
                                    3.6 Posting content on website / App
                                     You hereby declare that you will never post any content that is harmful, threatening,
                                    abusive, harassing, tortious, indecent, defamatory, pedophilic, blasphemous,
                                    discriminatory, vulgar, profane, obscene, libelous, hateful or otherwise objectionable,
                                    invasive of another’s privacy, relating to or encouraging money laundering or gambling.
                                    LOKACI will not knowingly accept any Post which is in violation of the law. LOKACI has
                                    the right, in its sole discretion and without prior notice to you; to immediately remove
                                    any post that discriminates or is any way in violation of any law.
                                     You agree, undertake and confirm that you shall not host, display, upload, modify,
                                    publish, transmit, update or share any information which:
                                    1. Is misleading in any way;
                                    2. Pertains to another person and to which you do not have any right to;
                                    3. Is patently offensive to the online community, such as sexually explicit content, or
                                    content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical
                                    harm of any kind against any group or individual;
                                    4. Harasses or advocates harassments of another person;
                                    5. Involves the transmission of “junk mail”, “chain letters”, or unsolicited mass
                                    mailing or “spamming”;
                                    6. Promotes an illegal or unauthorized copy of another person’s copyrighted work;
                                    7. Impersonate another person;
                                    8. Harm minors in any way;
                                    9. Violates any law for the time being in force;
                                    10. Impersonate another person;
                                    11. Contains batch files, computer viruses or any other computer code, files or
                                    program designed to interrupt, destroy or limit the functionality of any computer
                                    resource; or contains any “Trojan horses”, worms, cancelbots, time bombs, easter
                                    eggs or other computer programming codes that involves infinite loop properties
                                    that may cause damage, detrimentally interfere with, diminish value of,
                                    surreptitiously intercept or expropriate any system, data or personal information;
                                    12. Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item,
                                    the dealing of which is prohibited or restricted in any manner under the
                                    provisions of any applicable law, rule, regulation or guideline for the time being
                                    in force.
                                    <h5>3.7 Relationship</h5>
                                     You and we are independent contractors, and nothing in this Agreement will create any
                                    partnership, joint venture, agency, franchise, sales representative between the you and
                                    us. You will have no authority to make or accept any offers or representations on our
                                    behalf. You will not make any statement, whether on your website/App or otherwise, that
                                    reasonably would contradict anything in this clause.
                                    <h5>3.8 Listing of service items</h5>
                                     As a registered employee, you are allowed to list your services on the LOKACI platform in
                                    accordance with the Policies which are incorporated by way of reference in this Terms
                                    and Conditions. You must be legally able to provide those services you list on our
                                    website / App. You must ensure that the listed items do not infringe upon the intellectual
                                    property, trade secret or other proprietary rights or rights of publicity or privacy rights of
                                    third parties. Listings may only include text descriptions, graphics and pictures that
                                    describe your service for sale. All listed items must be listed in an appropriate category
                                    on the LOKACI. The listing description of the item must not be misleading. You agree not
                                    to list those services in which you are not expert in providing them to the customers on
                                    our website / App. LOKACI reserves the right to delete such listings of the same service
                                    listed by you in various categories. You hereby declare the approval right is reserved by
                                    LOKACI, which implies that none of your service will not be shown over the LOKACI
                                    platform until it gets approved by the Admin panel.
                                    <h2><b>4. Disclaimer and Limit of Liability.</b></h2>
                                    <h5><b>4.1 No Warranty</b></h5>
                                    <p> To the extent allowed under law, LOKACI
                                        1. Disclaim all implied warranties and representations (e.g. warranties of
                                        merchantability, fitness for a particular purpose, accuracy of data, and
                                        noninfringement)
                                        2. Do not guarantee that the services will function without interruption or errors, and
                                        3. Provide the service (including content and information) on an “AS IS” and “AS
                                        AVAILABLE” basis.
                                        Some laws do not allow certain disclaimers, so some or all of these disclaimers may not apply to
                                        you.
                                    </p>
                                    <h5>4.2 Credit / Debit card Fraudulently </h5>
                                    <p> LOKACI will not be liable for any credit/debit card fraud. The liability for use of a card
                                        fraudulently will be on you and the onus to prove otherwise shall be exclusively on you.
                                        All information collected by us shall be governed as per our Privacy Policy. LOKACI will
                                        not be responsible for any transactions you make for using any portion of the Service(s).
                                        All claims and complaints for any failed or incorrect transactions must be directed to our
                                        payment processing partners and/or your bank (as the case may be).
                                    </p><h5>4.3 Action on side-effects of products use </h5>
                                    <p> If the LOKACI’s customer faces any discrepancies during the services or after the services
                                        and any side effects of the products used, the employee is solely responsible for any
                                        miss-happening. The company will not entertain any hearing neither from the customer
                                        nor from employee’s side. It’s advisable to employee to keep his/her mind awake while
                                        providing service.
                                    </p><h5>4.4 Disclosure of personal information </h5>
                                    <p> Disclosure of your personal information by any hacker or illegitimate source does not
                                        blame LOKACI directly or indirectly.</p>
                                    <h2><b>5. Termination.</b></h2>
                                    <h5>5.1 Termination from services.</h5>
                                     Both you and LOKACI may terminate this Contract at any time with notice to the other.
                                    On termination, you lose the right to access or use the Services. The following shall
                                    survive termination:
                                    1. Our rights to use and disclose your feedback;
                                    2. Members and/or Visitors’ rights to further re-share content and information you
                                    shared through the Service to the extent copied or re-shared prior to termination;
                                    3. Any amounts owed by either party prior to termination remain owed after
                                    termination.
                                    We can end this Contract anytime we want.
                                    <h5>5.2 Suspension of Account.</h5>
                                     LOKACI may suspend or terminate your use of the LOKACI or any Service if it believes, in
                                    its sole and absolute discretion that you have infringed, breached, violated, abused, or
                                    unethically manipulated or exploited any term of this document or anyway otherwise
                                    acted unethically. Notwithstanding anything in this clause, these terms will survive
                                    indefinitely unless and until LOKACI chooses to terminate them.
                                    <h5>5.3 Suspension/Legal Action.</h5>
                                     LOKACI reserves its right to take legal action against you under such circumstances:
                                    1. If you use any program, algorithm or methodology, or any similar or equivalent
                                    manual process, to copy, or in any way reproduce or circumvent the navigational
                                    structure or presentation of the Content on the App/Website, to obtain or
                                    attempt to obtain any data or information about the App/Website or the content
                                    through any means other than those are knowingly made available through the
                                    App.
                                    2. If you shall attempt to gain unauthorized access to the server, to any portion or
                                    network related to the App/Website, by hacking, or any other illegitimate means.
                                    3. If you probe, scan and test the vulnerability of the App/Website or of server or
                                    breach the security or authentication measures on the App/Website.
                                    4. You do not reserve look-up, tracing on any other user/employee or visitor to the
                                    App/Website.
                                    5. If you make any negative, defamatory comment or statement about LOKACI or
                                    the domain name used by us or otherwise engage in any conduct or action that
                                    might tarnish the image or reputation of LOKACI or dilute any LOKACI’s trade or
                                    service marks.
                                    6. If you take any action that imposes an unreasonable or disproportionately large
                                    load on the infrastructure of the App/Website, network or system of LOKACI.
                                    <h2><b>6. Advertisements.</b></h2>
                                    <h5>6.1 Copyright</h5>
                                     We may include your content in advertisements for the products and services of
                                    third parties to others without your separate consent (including sponsored content).
                                    However, we have the right, without payment to you or others, to serve ads near
                                    your content and information, and your social actions e.g. likes, comments, follows,
                                    shares on sponsored content and company pages may be visible, as noted in the
                                    Privacy Policy.
                                     All material on LOKACI, including images, illustrations, audio clips, and video clips, are
                                    protected by copyrights, trademarks, and other intellectual property rights. You must not
                                    copy, reproduce, republish, upload, post, transmit or distribute such material in any way,
                                    including by email or other electronic means and whether directly or indirectly and you
                                    must not assist any other person to do so. Without the prior written consent of the
                                    owner, modification of the materials, use of the materials on any other LOKACI or
                                    networked computer environment or use of the materials for any purpose other than
                                    personal, non-commercial use is a violation of the copyrights, trademarks and other
                                    proprietary rights, and is prohibited. Any use for which you receive any remuneration,
                                    whether in money or otherwise, is a commercial use for the purposes of this clause. It is
                                    expressly clarified that you will retain ownership of your account and shall solely be
                                    responsible for any content that you provide or upload when using any Service, including
                                    any text, data, information, images, photographs, music, sound, video or any other
                                    material which you may upload, transmit or store when making use of our various
                                    Service. However, we reserve the right to use/reproduce any content uploaded by you
                                    and you agree to grant royalty free, irrevocably, unconditionally, perpetually and
                                    worldwide right to us to use the content for reasonable business purpose.
                                     LOKACI may click photos or record audios/videos, for the purpose of commercial use
                                    over the Website/App, of LOKACI customers while receiving services, with the
                                    acknowledgement of customers. Such kind of recorded/clicked material/content are
                                    solely authorized for LOKACI. LOKACI may also ask you to click photos or record
                                    audios/videos, under this situation all the content/material is solely LOKACI’s property.
                                    You hereby declare that neither you will record/click audios/videos/photos for your
                                    commercial use nor will claim any copyright over such content/material.
                                    <h2><b>7. Fees and Payments.</b></h2>
                                    <h5>7.1 Registration Fees</h5>
                                    <p> Absolutely zero. LOKACI does not charge any amount for signing up process. However,
                                        LOKACI reserves the right to charge subscription and/or membership fees for a new
                                        subscription, by giving reasonable prior notice, in respect of any service or any other
                                        aspect of the LOKACI Platform anytime in future.</p>
                                    <h5>7.2 Charges for Advertisements</h5>
                                    <p> LOKACI does not demand any charge for featuring you on our Website/App. We don’t
                                        charge for any publicity and advertisements from you.</p>
                                    <h5>7.3 Payments</h5>
                                    <p> Employee agrees to accept only that amount from customers that has been sent in
                                        details from LOKACI to the employee. LOKACI will not negotiate over the bargaining
                                        happened between the employee and the customer at all and will debit all its share of
                                        that service from the employee’s account.</p>
                                     Once the booking has been accepted by the employee, the employee won’t be able to
                                    modify / cancel the booking further. If the employee cancels the accepted booking
                                    he/she will be chargeable under these conditions:
                                    Cancellation duration before booking time Charges (% of the total service
                                    amount)
                                     24:00:00 - 23:00:00 2 %
                                     23:00:00 – 20:00:00 5 %
                                     20:00:00 – 16:00:00 20 %
                                     16:00:00 – 12:00:00 50 %
                                     12:00:00 – 06:00:00 75 %
                                     06:00:00 – 00:00:00 100%
                                     If the employee doesn’t able to reach at customer’s location on the given time window
                                    provided by LOKACI, the customer has the right to cancel the whole booking even. In
                                    this situation, LOKACI will not entertain any query regarding cancellation of booking. As
                                    the booking has been cancelled, the employee may have to return empty hands.
                                     If the employee makes the customer wait beyond the time window provided by LOKACI
                                    to the customer as well as employee, the customer has the right to cancel the booking.
                                    <h5>7.4 Taxation</h5>
                                    <p> All the LOKACI services works under the law of Indian Government, all the services are
                                        bound under the Financial act of Indian Government. Currently, LOKACI follows GSTintroduced
                                        as The Constitution (One hundred and first amendment) Act 2017, following
                                        the passage of Constitution 122nd amendment bill. All the rates will be applicable as per
                                        GST.</p><p>
                                    </p><h5>7.5 Pricing</h5>
                                    <p> LOKACI holds all of its right in deciding the price of each and every service uploaded by
                                        you through your account on any of the LOKACI platform, you agree that you will not
                                        interfere in any pricing and incentive schemes. However, your base price will be retained
                                        throughout the whole contract.</p>
                                    <p> In case of home appointments, all the prices shown on the LOKACI platform already
                                        includes the travelling charges, you are not supposed to collect any amount from the
                                        customer/user claiming the travelling/waiting charges.</p>
                                    <h2><b>8. Dispute resolutions.</b></h2>
                                    <h5>8.1 Redressal of Grievance</h5>
                                    <p> Sending a request in writing or through email signed with electronic signature
                                        identifying the content alleged to be in infringement of your rights as the rightful owner
                                        or affecting you prejudicially;</p>
                                    <p> Providing your contact information including email, address, and telephone number
                                        where you can be contacted if required.</p>
                                    <p> Giving a declaration cum undertaking along with necessary documents establishing you:</p>
                                     as the rightful owner of the content to be disabled/affecting you prejudicially,
                                     as an affected person, stating that the information submitted is true, complete &amp;
                                    accurate and no material fact has been hidden, and also stating that LOKACI, its
                                    Affiliates, Directors, employees, including Grievance Officer shall not be liable for any loss
                                    or damage or claim for relaying on such requests<p></p>
                                    <p> You may forward your request / complaints to Grievance Officer.</p>
                                    <p> Contact: Email: <b>grievance.officer@lokaci.com</b></p>
                                    <h5>8.2 Governing Law and Jurisdiction</h5>
                                    <p> If a dispute arises regarding these terms of use, the laws of the Republic of India will
                                        apply and shall be subject to the exclusive jurisdiction of courts in New Delhi, India.
                                    </p>
                                    <h2><b>9. Indemnity.</b></h2>
                                    <h5>9.1 Quid pro quo</h5>
                                    <p> You shall indemnify and hold harmless LOKACI, its owner, licensee, affiliates, subsidiaries,
                                        group companies (as applicable) and their respective officers, directors, agents, and
                                        workers, from any claim or demand, or actions including reasonable attorneys' fees,
                                        made by any third party or penalty imposed due to or arising out of Your breach of this
                                        Terms of Conditions, privacy Policy and other Policies, or Your violation of any law, rules
                                        or regulations or the rights (including infringement of intellectual property rights) of a
                                        third party.</p>
                                    <h2><b>10. General Terms.</b></h2>
                                    <h5>10.1 Important points</h5>
                                    <b>Here are some important details about this Contract.</b>
                                    <p> If a court with authority over this Contract finds any part of it not enforceable, you and
                                        we agree that the court should modify the terms to make that part enforceable while still
                                        achieving its intent. If the court cannot do that, you and we agree to ask the court to
                                        remove that unenforceable part and still enforce the rest of this Contract. To the extent
                                        allowed by law, the English language version of this Contract is binding and other
                                        translations are for convenience only. This Contract (including additional terms that may
                                        be provided by us when you engage with a feature of the Services and during offers and
                                        incentive schemes) is the only agreement between us regarding the Services and
                                        supersedes all prior agreements for the Services.
                                    </p>
                                    <p> If we don't act to enforce a breach of this Contract, that does not mean that LOKACI has
                                        waived its right to enforce this Contract. You may not assign or transfer this Contract (or
                                        your membership or use of Services) to anyone without our consent. However, you agree
                                        that LOKACI may assign this Contract to its affiliates or a party that buys it without your
                                        consent. There are no third-party beneficiaries to this Contract.
                                    </p>
                                    <p> You agree that the only way to provide us legal notice is at the Electronic addresses
                                        provided. It’s your responsibility to legally proceed further only after you get the
                                        received message for your legal notice.
                                    </p>
                                    <h5>10.2 Incentive programs</h5>
                                    <p> LOKACI may from time to time introduce referral and/or incentive based programs for
                                        you and its users / customers. These program(s) maybe governed by their respective
                                        terms and conditions. By participating in those programs, you are bound by the program
                                        terms and conditions as well as the LOKACI Platform terms. Further, LOKACI reserves the
                                        right to terminate/suspend yours account and/or credits/points earned and/or
                                        participation in the program if LOKACI determines in its sole discretion that the you have
                                        violated the rules of the program and/or has been involved in activities that are in
                                        contravention of the program terms and/or LOKACI platform terms or has engaged in
                                        activities which are fraudulent/unlawful in nature. Furthermore, LOKACI reserves the right
                                        to modify, cancel and discontinue its program without any prior notices.
                                    </p>
                                    <h5>10.3 Changes to the terms</h5>
                                    <p> We reserve the right to amend the terms of this Contract from time to time entirely at its
                                        own discretion, however we will provide you a notification if we do and we agree that
                                        changes cannot be retroactive. You shall be responsible for checking these Terms from
                                        time to time and ensure continued compliance with these Terms. Your use of LOKACI
                                        Website/App after such amendment in the Terms shall be deemed as your express
                                        acceptance to such amended terms; if you don't agree to these changes, you must stop
                                        using the Services.
                                    </p>
                                    <h2><b>11. Privacy Policy</b></h2>
                                    <p> We view protection of your privacy as a very important principle. We understand clearly
                                        that you and your Personal Information is one of our most important assets. We store
                                        and process your Information including any sensitive financial information collected as
                                        defined under the Information Technology Act, 2000 and Rules there under. We may
                                        share personal information with our other corporate entities and affiliates.
                                    </p>
                                    <p> Please note that we may disclose information about you to third parties or government
                                        authorities if we believe that such a disclosure is reasonably necessary to:
                                        1. take actions regarding suspected illegal activities;
                                        2. enforce or apply our Terms and Conditions;
                                        3. comply with legal process or other government inquiry, such as a search warrant,
                                        subpoena, statute, judicial proceeding, or other legal process/notice served on us;
                                        4. protect our rights, reputation, and property, or that of our users, affiliates, or the
                                        general public.
                                    </p>
                                    <p> We and our affiliates will share/sell some or all of your personal information with another
                                        business entity should we (or our assets) plan to merge with, or be acquired by that
                                        business entity, or re-organization, amalgamation, restructuring of business. We have
                                        developed this Privacy Policy to protect Your personal/financial information and keep it
                                        confidential. By visiting Our LOKACI platform, you are accepting and consenting to the
                                        practices described in this Privacy Policies. The following Privacy Policy and Cookie Policy
                                        outlines the information we may process and how we may use that information to better
                                        serve you while using our LOKACI platform.
                                    </p>
                                    <p> Third Party. At times we may tie-up with third parties, brand owners or other partners
                                        and make available certain offers, incentives or events or special schemes. In such
                                        instances, your personal information may be shared with such third parties and/or may
                                        become available to them or be disclosed to them, such third parties may have their own
                                        applicable privacy rules and We shall not be liable for the use or misuse of Your
                                        information by such third parties. </p>
                                    <p>Note: <u>We only collect your Personal Information to conduct our business and to enable us to
                                        deliver and improve our services. We do not for any reason whatsoever sell your
                                        Correspondence Information to any third party.</u></p>

                                    <h2><b>12. LOKACI Dos and Don’ts.</b></h2>
                                    <h5>12.1 Information security</h5>
                                    <p> We are committed to safeguard the security and confidentiality of any information you
                                        provide to us. We understand that you accept the inherent security implications of
                                        providing information over the internet or world wide web and will not hold us
                                        responsible for any breach of security or disclosure of personal information. If you
                                        become aware of any violation of the security of data or the Agreement, please contact
                                        us immediately.</p>
                                    <h5>12.2 Financial Information</h5>
                                    <p> We receive credit/ debit card details including card number and expiry date in encrypted
                                        form, details for net banking services and Wallet services. Please be advised that LOKACI
                                        ensures the highest degree of data security, while encrypting as per the guidelines
                                        specified by (PCI DSS) Payment Cards Industry Data Security Standard.</p>
                                    12.3 Account History
                                    <p> Your account history with us including (without limitation) all billing information and
                                        communications, payment history etc. We maintain this in encrypted form on secure
                                        servers. However, your transactions details may be preserved by LOKACI for purposes of
                                        tax or regulatory compliance.</p>
                                    <h5>12.4 Neutrality</h5>
                                    <p> User/customer reviews or ratings for your service and(or) parlor/salon/spa do not reflect the opinion of LOKACI. LOKACI receives multiple reviews or ratings for parlor/salon/spa by users/customers, which reflect the opinions of the users. It is pertinent to state that each and every review posted on Website/App is the personal opinion of the user/customer/reviewer only. LOKACI is a neutral platform, which solely provides a means of communication between users/customer/reviewers including users or parlor’s/salon’s/spa’s owners/representatives with access to the parlor/salon/spa business page. The advertisements published on the LOKACI Platform are independent of the reviews received by such advertisers. We are a neutral platform and we don’t arbitrate disputes, however in case if someone writes a review that the parlor/salon/spa does not consider to be true, the best option for the salon/parlor/spa representative would be to contact the reviewer or post a public response in order to clear up any misunderstandings. If the parlor/salon/spa believes that any particular user’s review violates any of the LOKACI’s policies, the parlor/salon/spa may write to us at <u>neutrality@lokaci.com</u> and bring such violation to our attention. LOKACI may remove the review in its sole discretion if review is in violation of the Terms, or content guidelines and policies or otherwise harmful to the services. </p>
                                    <h2><b>13. Complaints.</b></h2>
                                    <h5>13.1 Investigation</h5>
                                    <p> If LOKACI made aware of your problematic behavior with the customers, we may contact you for investigation. Depending upon the nature of concern, LOKACI may put a hold on your membership account.</p>
                                    <h2><b>14. How to contact us.</b></h2>
                                    <p> When you use the App/Website or send emails or other data, information or communication to us, you agree and understand that you are communication with us through electronic records and you consent to receive communications via electronic records from us periodically and as and when required. We may communicate with you
                                        by email or by such other mode of communication, electronic or otherwise.</p>
                                    You may leave your message at contact us feature/request a quote in the App/Website.

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TermsOfUse
import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from '../FAQs/InPageFAQCard.module.css'


const InPageFAQCard = props => {
    let item = props.item;
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={styles.eachFaqWrapper}>
            <div className={styles.efHeader}
                onClick={() => setIsOpen(!isOpen)}
            >
                <h5>{item.question}</h5>
                {isOpen == false ?
                    <FaChevronDown />
                    :
                    <FaChevronUp />
                }
            </div>
            {isOpen == true &&
                <div className={styles.efContent}>
                    <p>
                        {item.answer}
                    </p>
                </div>
            }
        </div>
    )
}

export default InPageFAQCard
import React from 'react'
import styles from '../FAQs/InPageFAQs.module.css'
import InPageFAQCard from './InPageFAQCard'

const InPageFAQs = props => {

    let data = props.data;

    return (
        <div className={styles.mainWrapper}>
            {data.length > 0 ?
                <div className={styles.mainContainer}>
                    <div className={styles.mainHeader}>
                        <div>
                            <h3>Frequently Asked Questions</h3>
                        </div>
                    </div>
                    <div className={styles.mainContent}>
                        {data.map((item, index) => (
                            <InPageFAQCard
                                item={item}
                                key={index}
                            />
                        ))}

                        <div className={styles.stillHaveQuestionWrapper}>
                            <div>
                                <h4>
                                    Still have a question?
                                </h4>
                                <p>
                                    If you cannot find answer to your questions in our FAQs, 
                                    you can always contact us. We will answer to you shortly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    No Faqs available
                </div>
            }


        </div>
    )
}

export default InPageFAQs
import React from 'react'
import styles from '../OfferSliders/BannerSlider.module.css'

const BannerSlider = props => {
  let items = props.data;

  if(props.data===undefined) {
    return(
      <div></div>
    )
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainContent}>
        <div className={styles.sliderWrapper}>

          {items.map((item, index) => (
            <div className={styles.eachSliderWrapper} key={index}>
              <img src={item.url} className={styles.eachImg} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BannerSlider
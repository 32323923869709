import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { BsArrowRight } from 'react-icons/bs'
import FooterAtBridalMakeups from '../../Components/Footer/FooterAtBridalMakeups'
import AtBridalMakeups from '../../Components/Headers/AtBridalMakeups'
import AtPreBridalMakeups from '../../Components/Headers/AtPreBridalMakeups'
import BannerSlider from '../../Components/OfferSliders/BannerSlider'
import BookPreBridalConsultation from '../../Components/PreBridal/BookPreBridalConsultation'
import styles from '../Makeups/PreBridalPackages.module.css'

import ReactGA from 'react-ga';

const PreBridalPackages = () => {
    const landscapeThumbnail = 'https://res.cloudinary.com/iamironman/image/upload/v1667533276/landscape-thumbnail-red_bnmmmi.webp';
    const portraitThumbnail = 'https://res.cloudinary.com/iamironman/image/upload/v1667533276/landscape-thumbnail-red_bnmmmi.webp';
    const landscapeVideo = 'https://res.cloudinary.com/drotwjbzs/video/upload/v1667388255/Final_Comp_oyxz1r.mp4';
    const portraitVideo = 'https://res.cloudinary.com/drotwjbzs/video/upload/v1667388255/Final_Comp_oyxz1r.mp4';

    const [packageCategories, setPackageCategories] = useState([
        {
            "identifier": "skinTreatmentPbp",
            "title": 'Skin Care / Treatments',
            "description": 'From basic clean-up to advance facials, our experts recommend the best suited treatments for your skin, so that you look flawless on your special day.',
            "benefits": '',
            "longDescription": 'Look glamorous from Head to Toe with Lokaci. Get pre-bridal treatment for your face, hands, feet, and body. Get expert-recommended services from the handpicked facial for your skin type to the best body polishing services to look stunning on your D-Day. From bride/groom to guests, there is something for everyone!!',
            "secondDescription": 'We offer various services under Skincare/Bodycare Treatments such as waxing, body polishing, body massage, manicure, pedicure, facials, cleanup, etc. We have created some handpicked packages for everyone with our experts adding more services or altering the package to meet client demands.',
            "servicesIncluded": [
                "Regular Facials", "Korean Facials", "Derma", "Bridal Facial"
            ]
        },
        {
            "identifier": "hairTreatmentPbp",
            "title": 'Hair Treatments',
            "description": 'From fixing your frizzy hairs to offering you the smoother experience of hair extensions, we are dedicated to make you look awesome.',
            "benefits": '',
            "longDescription": 'To complete your look, we offer the best hair care services and treatments. From frizzy hair to dandruff issues, we have solutions for everything. Exclusive hair services to make you ready for the red carpet events aka your wedding day like celebrities.',
            "secondDescription": 'From specialized hair care treatments to adding colors to your hair, you’ll get everything at Lokaci. Get yourself pampered with a variety of hair services such as the hair spa as per hair requirement, Hair Color, Hair Extensions, Keratin, Botox, Smoothening, and many more services stored in for everyone!!',
            "servicesIncluded": [
                "Keratin Treatment", "BOTOX Treatment", "Moroccon Oil Treatment", "Nano-plastia"
            ]
        },
        {
            "identifier": "relaxationTherapyPbp",
            "title": 'Relaxation Therapies',
            "description": 'We know that starting a new life could be cumbersome sometime, you need a little time to  recharge yourself with our relaxing massage therapies.',
            "benefits": '',
            "longDescription": 'Rejuvenate and Relax before the Wedding day with our exclusive massage and relaxation therapies. Ease muscle tension, headache, and pain with the best massages such as head massage, body massage, etc.',
            "secondDescription": '',
            "servicesIncluded": [
                "Aroma Massage", "Deep-Tissue Massage", "Swedish Massage"
            ]
        }
    ])


    const [sliderData, setSliderData] = useState([
        {
            "url": "https://res.cloudinary.com/drotwjbzs/image/upload/v1667647899/Offer-container/master-file-home-page-offer-container-post_2_nuasg2.png",
            "alt": "lokaci-offer-image"
        },
        {
            "url": "https://res.cloudinary.com/drotwjbzs/image/upload/v1667663376/Offer-container/master-file-home-page-offer-post_3_1_rqj4hg.jpg",
            "alt": "lokaci-offer-image"
        },
        {
            "url": "https://res.cloudinary.com/drotwjbzs/image/upload/v1667796997/Offer-container/master-file-home-page-offer-post_5_ysjy5e.png",
            "alt": "lokaci-offer-image"
        },

    ])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    
    }, [])
    
    


    return (
        <div className={styles.mainWrapper}>
            <Helmet>
                <title>Pre-bridal Packages For your wedding</title>
                <meta 
                    name="description" 
                    content="Get best pre-bridal packages for your wedding, whether it's about skin, hair, or your relaxation therapies. We help you to look flawless on your wedding day.">
                </meta>

                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "Get the pre-bridal package for wedding",
                        "description": "This wedding season, Lokaci is introducing the pre-bridal packages anywhere in India, the world's largest beauty/make-up/hair aspirants and salons community. ",
                        "thumbnailUrl": ["`+ landscapeThumbnail + `", "` + portraitThumbnail + `"],
                        "uploadDate": "2022-11-01T08:00:00+05:30",
                        "duration": "PT0M54S",
                        "contentUrl": "`+ landscapeVideo + `",
                        "embedUrl": "`+ landscapeVideo + `",
                        "interactionStatistic":
                        {
                            "@type": "InteractionCounter",
                            "interactionType":
                            {
                                "@type": "WatchAction"
                            },
                            "userInteractionCount": 5618
                        },
                        "regionsAllowed": "IN,US,NL"
                    }`
                    }
                </script>

                

            </Helmet>
            <AtPreBridalMakeups />
            <div className={styles.mainContainer}>
                <div className={styles.heroVideoWrapper}>
                    <div className={styles.videoWrapper}>
                        <video
                            src="https://lokaci.s3.ap-south-1.amazonaws.com/home-v2.mp4"
                            muted={true}
                            autoPlay={true}
                            loop={true}
                        >
                        </video>
                    </div>
                    <div className={styles.videoCoverWrapper}>
                        <div className={styles.videoCoverContainer}>
                            <div className={styles.upperPartCover}>
                                <h2>
                                    Glam yourself up
                                </h2>
                                <p>
                                    with Lokaci pre-bridal packages
                                </p>
                            </div>

                            <div className={styles.lowerPartCover}>
                                {packageCategories.map((item, index) => (
                                    <div className={styles.eachCardWrapper} key={index + "JI"}>
                                        <div className={styles.cardTitle}>
                                            {item.title}
                                        </div>
                                        <div className={styles.cardContent}>
                                            <p>
                                                {item.description}
                                            </p>
                                        </div>
                                        <div>
                                            <a className={styles.exploreBtn}
                                                href={"#" + item.identifier}
                                            >
                                                <div className={styles.exploreBtnTxt}>
                                                    Explore
                                                </div>
                                                <div className={styles.exploreBtnIcon}>
                                                    <BsArrowRight size={24} />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>

                <div className={styles.firstPaintWrapper}>
                    <div className={styles.whatWeOfferInPackageWrapper}>
                        <div className={styles.fpwBackground}>

                        </div>
                        <div className={styles.wwoipContainer}>
                            {packageCategories.map((itemDet, indexDet) => (
                                <div className={styles.eachWwoipContainer}
                                    key={indexDet + "JII"}
                                    id={itemDet.identifier}
                                >
                                    <div className={styles.headerBodyWrapper}>
                                        <div className={styles.categoryIconWrapper}>
                                            <img src="https://e7.pngegg.com/pngimages/710/645/png-clipart-cartoon-girl-skin-care-mask-apply-mask-skin-care.png" />
                                        </div>
                                        <div className={styles.categoryContentWrapper}>
                                            <div className={styles.categoryContentTitle}>
                                                {itemDet.title}
                                            </div>
                                            <div className={styles.longDescription}>
                                                <p>
                                                    {itemDet.longDescription}
                                                </p>
                                            </div>
                                            <div className={styles.secondDescription}>
                                                <p>
                                                    {itemDet.secondDescription}
                                                </p>
                                            </div>
                                            <div className={styles.servicesIncludedWrapper}>
                                                <ul>
                                                    {itemDet.servicesIncluded.map((itemSer, indexSer) => (
                                                        <li key={indexSer}>{itemSer}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.ewcFooter}>
                                        <a href="tel:+91-8929460356" className={styles.scheduleBtn}>Schedule A Meeting</a>
                                        <a href="https://wa.me/918929460356?text=Hi,%0AI%20want%20to%20get%20consulation%20about%20pre-bridal%20packages." className={styles.consultNowBtn}>
                                            Consult Now
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <BannerSlider
                    data={sliderData}
                />


                <BookPreBridalConsultation />



                <div className={styles.howWeDoTheMagicWrapper}>
                    <h2>
                        Wonder, how we do the magic?
                    </h2>
                    <h6>
                        Before booking any of our services, we provide our clients with a simple process. We accomplish this by educating our clients.
                    </h6>
                    <p>
                        This is how it works:
                    </p>
                    <ul>
                        <li>
                            Step 1: Schedule your pre-wedding consultation with us.
                        </li>
                        <li>
                            Step 2: Our Expert will note down your specifications, wedding dates, product allergies, and so on.
                        </li>
                        <li>
                            Step 3: Perform small tests to determine your skin/hair type.
                        </li>
                        <li>
                            Step 4: Create a package based on your needs and problems.
                        </li>
                        <li>
                            Step 5: Add new services or modify existing ones to meet your changing needs.
                        </li>
                    </ul>
                </div>

                <FooterAtBridalMakeups />
            </div>
        </div>
    )
}

export default PreBridalPackages
import React from 'react'
import styles from '../SearchBar/GlobalSearch.module.css'

const GlobalSearch = () => {
  return (
    <div>
      <div>
        <input
          placeholder="You can search for anything"
        />
      </div>
    </div>
  )
}

export default GlobalSearch
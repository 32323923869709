import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../BridalMakeups/OtherValuableServicesForBrides.module.css'
import { useNavigate } from 'react-router';

const OtherValuableServicesForBrides = () => {
    let navigate = useNavigate();

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.titleSection}>
                    <div className={styles.titleCaption}>
                        <p>
                            Explore
                        </p>
                        <h2>
                            Our other
                        </h2>
                        <h2>
                            valuable services
                        </h2>
                    </div>
                    <div className={styles.titleSubCaption}>
                        <h4>
                            To make your special day stress free, we are packed with all the services required
                        </h4>
                    </div>
                    <div className={styles.exploreBtnWrapper}>
                        <button onClick={() =>  navigate("/pre-bridal-package")}>Explore All</button>
                    </div>
                </div>
                <div className={styles.cardSection}>
                    <div className={styles.eachCardWrapper}>
                        <div className={styles.eachCardUpper}>
                            <img src="https://lokaci.s3.ap-south-1.amazonaws.com/woman-having-hair-treatment-in-hair-salon.webp" />
                        </div>
                        <div className={styles.eachCardLower}>
                            <div className={styles.cardBigTitle}>
                                <h4>
                                    Hair Care & Treatments
                                </h4>
                            </div>
                            <div className={styles.cardSmallTitle}>
                                <p>
                                    Not only the protection from falls, damage, roughness, dryness, and other chemicals, we do offer the best shades with premium hairstyles.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.eachCardWrapper}>
                        <div className={styles.eachCardUpper}>
                            <img src="https://lokaci.s3.ap-south-1.amazonaws.com/Bridal-Skin-Care-Treatment1.webp" />
                        </div>
                        <div className={styles.eachCardLower}>
                            <div className={styles.cardBigTitle}>
                                <h4>
                                    Skin Care & Treatments
                                </h4>
                            </div>
                            <div className={styles.cardSmallTitle}>
                                <p>
                                    Our skin experts look over your skins and treats you with advance facial, tanning, polishing treatments to give you a flawless skin.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.eachCardWrapper}>
                        <div className={styles.eachCardUpper}>
                            <img src="https://lokaci.s3.ap-south-1.amazonaws.com/bridal-mehandi-3.webp" />
                        </div>
                        <div className={styles.eachCardLower}>
                            <div className={styles.cardBigTitle}>
                                <h4>
                                    Bridal Mehandi
                                </h4>
                            </div>
                            <div className={styles.cardSmallTitle}>
                                <p>
                                    From bridal to Arabic, from Rajasthani to Moroccan, we have the experts in all types of mehandi design.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OtherValuableServicesForBrides
import React, { useState } from 'react'
import { BsDot } from 'react-icons/bs'
import styles from '../BridalMakeups/PlacesAtBridal.module.css'

const PlacesAtBridal = props => {
    const [citiesData, setCitiesData] = useState([
        {
            "cityLabel" : 'Delhi',
            "colorCode" : '#da1884',
            "navigateTo" : '',
        },
        {
            "cityLabel" : 'Noida',
            "colorCode" : '#566E2D',
            "navigateTo" : '',
        },
        {
            "cityLabel" : 'Gurugram',
            "colorCode" : '#5AA0D6',
            "navigateTo" : '',
        },
        {
            "cityLabel" : 'Chandigarh',
            "colorCode" : '#C19AC7',
            "navigateTo" : '',
        },
        {
            "cityLabel" : 'Jaipur',
            "colorCode" : '#DC9001',
            "navigateTo" : '',
        },
        {
            "cityLabel" : 'Faridabad',
            "colorCode" : '#da1884',
            "navigateTo" : '',
        },

    ])

    return (
        <div className={styles.mainContainer}>
            <div className={styles.contentWrapper}>
                <div className={styles.eachCol}>

                </div>
                <div className={styles.eachCol}>
                    <div className={styles.colUpper}>
                        <h2>
                            We are with you in your journey.
                        </h2>
                        <p>
                            Choose us for
                        </p>
                        <p>
                            Professional. Affordable. Luxury
                        </p>
                    </div>
                    <div className={styles.colLower}>
                        {citiesData.map((item, index) => (
                            <div className={styles.eachLocation}
                                key={index}
                            >
                                <BsDot color={item.colorCode} size={34} /> {item.cityLabel}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlacesAtBridal
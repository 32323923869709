import React, { useState } from 'react'
import styles from '../BridalMakeups/BridalReviewSection.module.css'
import { MdComment } from "react-icons/md";
import { TfiCommentAlt } from "react-icons/tfi";
import { IoBrushOutline, IoHeartOutline, IoPricetagOutline } from "react-icons/io5";
import BridalReviewCard from './BridalReviewCard';


const BridalReviewSection = props => {
    const [reviewData, setReviewData] = useState([
        {
            'identifier' : '1',
            'comment' : 'Love their makeup and hair team. From consultation to the final look, they ensured a seamless experience. Everything was done timely and elegantly. The team stayed in regular touch with us and was very flexible with our requirements and changes.',
            'reviewer' : 'Pallavi S.'
        },
        {
            'identifier' : '2',
            'comment' : 'They had Makeup artists with more than five years expert in the industry. Not just makeup, but we got expert bridal consultation which made me perfect ready for my D-Day. I just explained my requirements and they come up with an exceptionally stunning look.',
            'reviewer' : 'Nandini Raj'
        },
        {
            'identifier' : '2',
            'comment' : "I got makeup booked for my sister's wedding. They provide a spectacular makeup look for everyone. From the bride to the guests their team made sure that everyone rocked the wedding. Really satisfied with the final bridal look. The base was flawless and the eye base was stunning.",
            'reviewer' : 'A Dixit'
        },

    ])

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainHeader}>
                <div className={styles.mainCaptionIcon}>
                    <TfiCommentAlt size={48} color="#00000086" />
                </div>
                <div className={styles.mainCaption}>
                    <h2>Brides do love us, grooms and their families too!</h2>
                    <p>Thousands of brides like you have chosen Lokaci so far. Hear what they have to say about us!</p>
                </div>
            </div>
            <div>
                <div className={styles.uspWrapper}>
                    <div className={styles.eachUspWrapper}>
                        <div className={styles.eachUspIcon}>
                            <IoBrushOutline size={40} color="#da1884" />
                        </div>
                        <div className={styles.eachUspTxt}>
                            <div className={styles.eachUspTxtBold}>Thousands of Makeup Done</div>
                            <div className={styles.eachUspTxtReg}>by lokaci experts</div>
                        </div>
                    </div>
                    <div className={styles.eachUspWrapper}>
                        <div className={styles.eachUspIcon}>
                            <IoHeartOutline size={40} color="#da1884" />
                        </div>
                        <div className={styles.eachUspTxt}>
                            <div className={styles.eachUspTxtBold}>100% of brides </div>
                            <div className={styles.eachUspTxtReg}>love us</div>
                        </div>
                    </div>
                    <div className={styles.eachUspWrapper}>
                        <div className={styles.eachUspIcon}>
                            <IoPricetagOutline size={40} color="#da1884" />
                        </div>
                        <div className={styles.eachUspTxt}>
                            <div className={styles.eachUspTxtBold}>Value for Money</div>
                            <div className={styles.eachUspTxtReg}>most affordable and luxurious</div>
                        </div>
                    </div>
                </div>

                <div className={styles.reviewCardsWrapper}>
                    {reviewData.map((item, index) => (
                        <div className={styles.eachReviewCardWrapper}
                            key={index}
                        >
                            <BridalReviewCard 
                                reviewData={item}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BridalReviewSection
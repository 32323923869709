import React, { useState } from 'react'
import styles from '../BridalMakeups/OfferingsToGroom.module.css'
import { IoIosCall } from 'react-icons/io'
import { IoLogoWhatsapp } from 'react-icons/io5'

const OfferingsToGroom = () => {
    const [data, setData] = useState([
        {
            "title": "Regular Makeup",
            "description": "Apple",
            "base": "Forever-52 / PAC HD Base",
            "included": [
                "Advance Haircut",
                "Beard Styling / Grooming",
            ],
            "priceRange": "4k"
        },
        {
            "title": "HD Makeup",
            "description": "Apple",
            "base": "Huda Beauty",
            "included": [
                "Advance Haircut",
                "Beard Styling / Grooming",
            ],
            "priceRange": "6k"
        },
    ])

    const onCallRequest = () => {
        document.getElementById("sendQueryOnBridal").click();
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                {/* <div className={styles.eachOptionWrapper}>
                    <div className={styles.optionTitle}>
                        <h2>
                            Regular Makeup
                        </h2>
                    </div>
                    <div>
                        <div className={styles.expertiseTxt}>
                            We do care about you skin and hairs.
                            We are dedicated to help you shine on your special day, hence we are offering our special services for your skin and hair.
                            Our expert at Lokaci observe, analyse, and understands your skin type and hair structures and recommend you the best treatments available.
                        </div>
                        <div>
                            <button className={styles.askTheExpertBtn}>
                                Ask the expert
                            </button>
                        </div>
                    </div>
                </div> */}

                {data.map((item, index) => (
                    <div className={styles.eachOptionWrapper} key={index}>
                        <div className={styles.optionTitle}>
                            <h2>{item.title}</h2>
                        </div>
                        <div>
                            <div>
                                <div className={styles.eachField}>
                                    <h5>Base:</h5>
                                    <p>{item.base}</p>
                                </div>
                                <div className={styles.eachField}>
                                    <h5>What's included : </h5>
                                    <ul>
                                        {item.included.map((itemInc, indexInc) => (
                                            <li key={indexInc+"LI"}>{itemInc}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div>
                                ₹{item.priceRange}
                            </div>
                            <div className={styles.actionWrapper}>
                                <div className={styles.shareableActions}>
                                    {/* <a 
                                        href="tel:+91-8929460356"
                                        className={styles.phoneActionBtn}
                                    >
                                        <IoIosCall size={16} color="black" />
                                    </a>
                                    <a href={`https://wa.me/918929460356?text=Hey%20Lokaci,%0AI%20want%20to%20know%20more%20about%20*` + item.title + `*%20for%20groom!%0AThanks`} className={styles.actionIcon}
                                        target="_blank"
                                        className={styles.phoneActionBtn}
                                    >
                                        <IoLogoWhatsapp size={16} color="green" />
                                    </a> */}
                                </div>
                                <div className={styles.performableActions}>
                                    {/* <button className={styles.bookDemoBtn}>
                                        Book Demo
                                    </button> */}
                                    <button className={styles.bookNowBtn}
                                        onClick={() => onCallRequest()}
                                    >
                                        Book Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                ))}

            </div>
        </div>
    )
}

export default OfferingsToGroom
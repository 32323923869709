import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { IoIosCall } from 'react-icons/io'
import { IoLogoWhatsapp } from 'react-icons/io5'
import styles from '../BridalMakeups/OfferingsToFriendNFamily.module.css'
import EachOfferingNav from './EachOfferingNav'

const OfferingsToFriendNFamily = () => {
    const [selectedCategory, setSelectedCategory] = useState('Day Party Make-up');
    const [selectedMakeup, setSelectedMakeup] = useState(
        {
            "title": "Regular Makeup",
            "description": "Apple",
            "base": "Base with Forever-52 (liquid) / powder Forever-52 loose powder",
            "eyeMakeup": [
                "2 eyeshadow, brown eye-makeup",
                "2 eyeshadow, shine + matte",
                "Brown smokey",
                "Nude eyemake-up (dewy base)"
            ],
            "priceRange": "2k-2.5k"
        }
    )

    const [data, setData] = useState([
        {
            "title": "Day Party Make-up",
            "types": [
                {
                    "title": "Reasonable Makeup",
                    "description": "Apple",
                    "base": "Base with Maybelline fitme foundation / powder fit me loose powder",
                    "eyeMakeup": [
                        "2 eyeshadow, brown eye-makeup",
                        "2 eyeshadow, shine + matte",
                        "Brown smokey",
                        "Nude eyemake-up (dewy base)"
                    ],
                    "priceRange": "1.5k"
                },
                {
                    "title": "Regular Makeup",
                    "description": "Apple",
                    "base": "Base with Forever-52 (liquid) / powder Forever-52 loose powder",
                    "eyeMakeup": [
                        "2 eyeshadow, brown eye-makeup",
                        "2 eyeshadow, shine + matte",
                        "Brown smokey",
                        "Nude eyemake-up (dewy base)"
                    ],
                    "priceRange": "2k-2.5k"
                },
                {
                    "title": "HD Makeup",
                    "description": "Apple",
                    "base": "PAC liquid HD foundation / powder - HD powder PAC",
                    "eyeMakeup": [
                        "2 eyeshadow, brown eye-makeup",
                        "2 eyeshadow, shine + matte",
                        "Brown smokey",
                        "Nude eyemake-up (dewy base)"
                    ],
                    "priceRange": "3-4k"
                },
            ]
        },
        {
            "title": "Night Party Make-up",
            "types": [
                {
                    "title": "Regular Makeup",
                    "description": "Apple",
                    "base": "Base with Forever-52 ultradefinition foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Smokey eye-makeup",
                        "Winged Smokey",
                        "Nude eye-makeup"
                    ],
                    "priceRange": "2-2.5k"
                },
                {
                    "title": "Reasonable Makeup",
                    "description": "Apple",
                    "base": "Base with Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "Golden brown halo eye-makeup",
                        "Any of her choice"
                    ],
                    "priceRange": "1.5k"
                },
                {
                    "title": "HD Makeup",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "Any of her choice"
                    ],
                    "priceRange": "3k"
                },
            ]
        },
        {
            "title": "Haldi Make-up",
            "types": [
                {
                    "title": "Reasonable Makeup",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "1 eyeshadow (shine + matter)",
                        "2 eyeshadow (shine + matte)",
                        "As per outfit"
                    ],
                    "priceRange": "1.5k"
                },
                {
                    "title": "Regular Makeup",
                    "description": "Apple",
                    "base": "Forever-52 ultradefinition foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "3-4 eyeshadow eye-makeup",
                        "1 colorful eye-makeup",
                        "2 colorful cut crease eye-makeup",
                        "1 colorful eye-makeup with glitters",
                    ],
                    "priceRange": "2-2.5k"
                },
                {
                    "title": "HD Makeup",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "3-4 eyeshadow eye-makeup",
                        "1 colorful eye-makeup",
                        "2 colorful cut crease eye-makeup",
                        "1 colorful eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "3k-4k"
                },
                {
                    "title": "Advance HD Makeup",
                    "description": "Apple",
                    "base": "Base with Huda Beauty HD Foundation",
                    "eyeMakeup": [
                        "2 colorful cut crease eye-makeup with glitter",
                        "1 colorful eye-makeup with glitters",
                        "colorful blur cut crease eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "4k-5k"
                },
                {
                    "title": "Airbrush Makeup",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "2 colorful cut crease eye-makeup with glitter",
                        "1 colorful eye-makeup with glitters",
                        "colorful blur cut crease eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "7k-8k"
                },
                {
                    "title": "Airbrush + HD Makeup",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "2 colorful cut crease eye-makeup with glitter",
                        "1 colorful eye-makeup with glitters",
                        "colorful blur cut crease eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "8k-10k"
                },
            ]
        },
        {
            "title": "Sangeet Make-up",
            "types": [
                {
                    "title": "Reasonable Makeup",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "Soft smokey",
                        "Pink and silver",
                        "Brown shine and matte",
                        "Brown blur cut crease",
                    ],
                    "priceRange": "1.5k"
                },
                {
                    "title": "Regular Makeup",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "2 eyeshadow with glitters",
                        "Pink soft smokey with glitter",
                        "Brown blur cut crease"
                    ],
                    "priceRange": "2k"
                },
                {
                    "title": "HD Makeup",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "2 eyeshadow with glitters",
                        "Pink soft smokey with glitter",
                        "Brown blur cut crease",
                        "Soft smokey",
                        "Pink and silver",
                        "Brown shine and matte",
                    ],
                    "priceRange": "3k"
                },
                {
                    "title": "Advance HD Makeup",
                    "description": "Apple",
                    "base": "Huda Beauty HD Foundation",
                    "eyeMakeup": [
                        "Cut crease",
                        "Colorful smokey",
                        "Pink smokey",
                        "Halo eyes brown",
                    ],
                    "priceRange": "4k-5k"
                },
                {
                    "title": "AirBrush Makeup",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Cut crease",
                        "Colorful smokey",
                        "Pink smokey",
                        "Halo eyes brown",
                    ],
                    "priceRange": "7k-8k"
                },
                {
                    "title": "AirBrush + HD Makeup",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Cut crease",
                        "Colorful smokey",
                        "Pink smokey",
                        "Halo eyes brown",
                    ],
                    "priceRange": "8k-10k"
                },
            ]
        },
        {
            "title": "Engagement Make-up",
            "types": [
                {
                    "title": "Regular Makeup",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist liquid foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Colorful smokey with shine",
                        "Pink sharp halo cut crease",
                        "Brown classic partial smokey"
                    ],
                    "priceRange": "2k"
                },
                {
                    "title": "Reasonable Makeup",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "Nude eye-makeup with liner",
                        "2 eyeshadow",
                        "2 eyeshadow colorful with shine",
                        "matte smokey"
                    ],
                    "priceRange": "1.5k"
                },
                {
                    "title": "HD Makeup",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "3k"
                },
                {
                    "title": "Advance HD Makeup",
                    "description": "Apple",
                    "base": "Base with Huda Beauty / Forever-52 multitasker palette",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "4k-5k"
                },
                {
                    "title": "Airbrush Makeup",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "7k-8k"
                },
                {
                    "title": "Airbrush + HD Makeup",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "8k-10k"
                },

            ]
        },
        {
            "title": "Cocktail Party Make-up",
            "types": [
                {
                    "title": "Regular Makeup",
                    "description": "Apple",
                    "base": "Base with Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Winged brown soft smokey",
                        "Black soft smokey",
                        "Spot light halo",
                        "Blur cut crease"
                    ],
                    "priceRange": "2k"
                },
                {
                    "title": "Reasonable Makeup",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "Nude eye-makeup with liner",
                        "2 eyeshadow",
                        "2 eyeshadow colorful with shine",
                        "matte smokey"
                    ],
                    "priceRange": "1.5k"
                },
                {
                    "title": "HD Makeup",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "3k"
                },
                {
                    "title": "Advance HD Makeup",
                    "description": "Apple",
                    "base": "Base with Huda Beauty / Forever-52 multitasker palette",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "4k-5k"
                },
                {
                    "title": "Airbrush Makeup",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "7k-8k"
                },
                {
                    "title": "Airbrush + HD Makeup",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "8k-10k"
                },
            ]
        },
        // {
        //     "title": "Traditional Bride Make-up",
        //     "types": [
        //         {
        //             "title": "Regular Makeup",
        //             "description": "Apple",
        //             "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo"
        //             ],
        //             "priceRange": "15-18k"
        //         },
        //         {
        //             "title": "HD Makeup",
        //             "description": "Apple",
        //             "base": "Huda Beauty HD foundation",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo",
        //             ],
        //             "priceRange": "20k"
        //         },
        //         {
        //             "title": "Airbrush Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo",
        //             ],
        //             "priceRange": "22k"
        //         },
        //         {
        //             "title": "Airbrush + HD Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush + Huda Beauty",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo",
        //             ],
        //             "priceRange": "22k-25k"
        //         },
        //     ]
        // },
        // {
        //     "title": "Bengali Make-up",
        //     "types": [
        //         {
        //             "title": "Regular Makeup",
        //             "description": "Apple",
        //             "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo"
        //             ],
        //             "priceRange": "15-18k"
        //         },
        //         {
        //             "title": "HD Makeup",
        //             "description": "Apple",
        //             "base": "Huda Beauty HD foundation",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo",
        //             ],
        //             "priceRange": "20k"
        //         },
        //         {
        //             "title": "Airbrush Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo",
        //             ],
        //             "priceRange": "22k"
        //         },
        //         {
        //             "title": "Airbrush + HD Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush + Huda Beauty",
        //             "eyeMakeup": [
        //                 "Brown partial smokey with winged liner",
        //                 "2 eye shadow with glitter",
        //                 "Spot light halo",
        //             ],
        //             "priceRange": "22k-25k"
        //         },
        //     ]
        // },
        // {
        //     "title": "Maharashtrian Make-up",
        //     "types": [
        //         {
        //             "title": "Regular Makeup",
        //             "description": "Apple",
        //             "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Cut crease",
        //                 "Smokey eyes with glitters"
        //             ],
        //             "priceRange": "15-18k"
        //         },
        //         {
        //             "title": "HD Makeup",
        //             "description": "Apple",
        //             "base": "Huda Beauty HD foundation",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Cut crease",
        //                 "Smokey eyes with glitters"
        //             ],
        //             "priceRange": "20k"
        //         },
        //         {
        //             "title": "Airbrush Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Cut crease",
        //                 "Smokey eyes with glitters"
        //             ],
        //             "priceRange": "22k"
        //         },
        //         {
        //             "title": "Airbrush + HD Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush + Huda Beauty",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Cut crease",
        //                 "Smokey eyes with glitters"
        //             ],
        //             "priceRange": "22k-25k"
        //         },
        //     ]
        // },
        // {
        //     "title": "Muslim Bride Make-up",
        //     "types": [
        //         {
        //             "title": "Regular Makeup",
        //             "description": "Apple",
        //             "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Pink Spot light halo",
        //                 "Soft Smokey eyes with glitters"
        //             ],
        //             "priceRange": "15-18k"
        //         },
        //         {
        //             "title": "HD Makeup",
        //             "description": "Apple",
        //             "base": "Huda Beauty HD foundation",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Pink Spot light halo",
        //                 "Soft Smokey eyes with glitters"
        //             ],
        //             "priceRange": "20k"
        //         },
        //         {
        //             "title": "Airbrush Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Pink Spot light halo",
        //                 "Soft Smokey eyes with glitters"
        //             ],
        //             "priceRange": "22k"
        //         },
        //         {
        //             "title": "Airbrush + HD Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush + Huda Beauty",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Pink Spot light halo",
        //                 "Soft Smokey eyes with glitters"
        //             ],
        //             "priceRange": "22k-25k"
        //         },
        //     ]
        // },
        // {
        //     "title": "South Indian Bride Make-up",
        //     "types": [
        //         {
        //             "title": "Regular Makeup",
        //             "description": "Apple",
        //             "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Half cut crease",
        //                 "Half cut with glitters"
        //             ],
        //             "priceRange": "15-18k"
        //         },
        //         {
        //             "title": "HD Makeup",
        //             "description": "Apple",
        //             "base": "Huda Beauty HD foundation",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Half cut crease",
        //                 "Half cut with glitters"
        //             ],
        //             "priceRange": "20k"
        //         },
        //         {
        //             "title": "Airbrush Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Half cut crease",
        //                 "Half cut with glitters"
        //             ],
        //             "priceRange": "22k"
        //         },
        //         {
        //             "title": "Airbrush + HD Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush + Huda Beauty",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "Half cut crease",
        //                 "Half cut with glitters"
        //             ],
        //             "priceRange": "22k-25k"
        //         },
        //     ]
        // },
        // {
        //     "title": "Christian Bride Make-up",
        //     "types": [
        //         {
        //             "title": "Regular Makeup",
        //             "description": "Apple",
        //             "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "--",
        //                 "--",
        //             ],
        //             "priceRange": "15-18k"
        //         },
        //         {
        //             "title": "HD Makeup",
        //             "description": "Apple",
        //             "base": "Huda Beauty HD foundation",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "--",
        //                 "--",
        //             ],
        //             "priceRange": "20k"
        //         },
        //         {
        //             "title": "Airbrush Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "--",
        //                 "--",
        //             ],
        //             "priceRange": "22k"
        //         },
        //         {
        //             "title": "Airbrush + HD Makeup",
        //             "description": "Apple",
        //             "base": "Airbrush + Huda Beauty",
        //             "eyeMakeup": [
        //                 "--",
        //                 "--",
        //                 "--",
        //                 "--",
        //             ],
        //             "priceRange": "22k-25k"
        //         },
        //     ]
        // },


    ])

    const selectedMakeupHandler = (category, k) => {
        setSelectedCategory(category);
        setSelectedMakeup(k);
    }

    const onCallRequest = () => {
        document.getElementById("sendQueryOnBridal").click();
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                <div className={styles.menuWrapper}>
                    {data.map((item, index) => (
                        <EachOfferingNav
                            item={item}
                            key={index}
                            category={item.title}
                            selectedCategory={selectedCategory}
                            selectedMakeupHandler={selectedMakeupHandler}
                            selectedMakeup={selectedMakeup}
                        />
                    ))}
                </div>


                <div className={styles.contentWrapper}>
                    <div className={styles.selectedContent}>
                        <div className={styles.selectedTitle}>
                            <h2>
                                {selectedCategory}
                            </h2>
                            <h3>
                                {selectedMakeup.title}
                            </h3>
                        </div>
                        <div>
                            <div className={styles.expertiseTxt}>
                                {/* We do care about you skin and hairs.
                                We are dedicated to help you shine on your special day, hence we are offering our special services for your skin and hair.
                                Our expert at Lokaci observe, analyse, and understands your skin type and hair structures and recommend you the best treatments available. */}
                                <div className={styles.baseContainer}>
                                    <h5>Base : </h5>
                                    <p>
                                        {selectedMakeup.base}
                                    </p>
                                </div>
                                <div className={styles.eyeMakeupContainer}>
                                    <h5>Eye Make-up : </h5>
                                    {selectedMakeup.eyeMakeup &&
                                        <ul>
                                            {selectedMakeup.eyeMakeup.map((it, indexIt) => (
                                                <li key={indexIt="LII"}>{it}</li>
                                            ))}
                                        </ul>
                                    }
                                </div>

                                <div className={styles.whatIncludedContainer}>
                                    <h5>What's included?</h5>
                                    <ul>
                                        <li>Saree Draping / Attire / Dressing</li>
                                        <li>Hair do</li>
                                        <li>Premium Eye lashes</li>
                                        <li>Premium Eye lenses</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                ₹{selectedMakeup.priceRange}
                            </div>
                            <div className={styles.actionWrapper}>
                                <div>
                                    {/* <a href="tel:+91-8929460356" className={styles.actionIcon}>
                                        <IoIosCall color="black" size={24} />
                                    </a>
                                    <a href={`https://wa.me/918929460356?text=Hey%20Lokaci,%0AI%20want%20to%20know%20more%20about%20*`+selectedMakeup.title+`*%20for%20`+selectedCategory+`%20for%20my%20friends,%20relatives,%20and%20family!%0AThanks`} className={styles.actionIcon}
                                        target="_blank"
                                    >
                                        <IoLogoWhatsapp color="green" size={24} />
                                    </a> */}
                                </div>
                                <div>
                                    {/* <button className={styles.bookDemo}>
                                        Book Demo
                                    </button> */}
                                    <button className={styles.bookNow}
                                        onClick={() => onCallRequest()}
                                    >
                                        Book Now
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default OfferingsToFriendNFamily
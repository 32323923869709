import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import WhatNext from '../Components/Content/WhatNext';
import MainFooter from '../Components/Footer/MainFooter';
import AtHome from '../Components/Headers/AtHome';
import IntroducingBridalMakeups from '../Components/Home/IntroducingBridalMakeups';
import BannerSlider from '../Components/OfferSliders/BannerSlider';
import styles from '../Pages/Home.module.css'

import ReactGA from 'react-ga';

const Home = () => {
    
    const ref = useRef(null);
    let navigate = useNavigate()

    var offerIndex = 0;
    useEffect(() => {
        offerSlider();

        ReactGA.pageview(window.location.pathname);
    }, [])


    function offerSlider() {
        var j;
        // var offerslides = document.getElementsByClassName("ofslidsP");
        var offerslides = ref.current.children;
        for (j = 0; j < offerslides.length; j++) {
            offerslides[j].style.display = "none";
        }
        offerIndex++;
        if (offerIndex > offerslides.length) {
            offerIndex = 1
        }
        offerslides[offerIndex - 1].style.display = "block";
        offerslides[offerIndex - 1].style.animation = "ofkeanimation2 .5s forwards";
        setTimeout(offerSlider, 3000);
    }


    const [sliderData, setSliderData] = useState([
        {
            "url" : "https://lokaci.s3.ap-south-1.amazonaws.com/home-img1.png",
            "alt" : "lokaci-offer-image"
        },
        {
            "url" : "https://lokaci.s3.ap-south-1.amazonaws.com/home-img2.jpeg",
            "alt" : "lokaci-offer-image"
        },
        {
            "url" : "https://lokaci.s3.ap-south-1.amazonaws.com/home-img3.png",
            "alt" : "lokaci-offer-image"
        },

    ])


    return (
        <div className={styles.mainContainer}>
            <Helmet>
                

            </Helmet>
            <div className={styles.firstImpressionWrapper}>
                <div className={styles.heroImageContainer}>
                    <img src="https://lokaci.s3.ap-south-1.amazonaws.com/home.png" />
                    {/* <img src="https://res.cloudinary.com/drotwjbzs/image/upload/v1662737682/main-img_4_fxczww.png" /> */}
                </div>
                <div className={styles.heroContentWrapper}>
                    <AtHome />

                    <div className={styles.bodyTransWrapper}>

                        <div className={styles.logoContent}>
                            <div>
                                <div className={styles.offSliders} ref={ref}>
                                    <p className={styles.ofslidsP} >Don't want to wait at Salon?</p>
                                    <p className={styles.ofslidsP} >Looking for your favorite stylist?</p>
                                    <p className={styles.ofslidsP} >Looking for a hygienic salon?</p>
                                </div>
                                <div className={styles.subCaption}>
                                    Discover best salon, hair-stylist and book your appointment in real-time.
                                </div>
                            </div>

                            <div className={styles.searchBarContainer}>
                                {/* <div>
                                    <input placeholder="Search for anything..."
                                        onFocus={() => navigate('search')}
                                    />
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <IntroducingBridalMakeups

            />

            <BannerSlider 
                data={sliderData}
            />

            <WhatNext />
       
            <MainFooter />
        </div>
    )
}

export default Home
import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { IoIosCall } from 'react-icons/io'
import { IoLogoWhatsapp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import styles from '../BridalMakeups/OfferingsToBride.module.css'
import EachOfferingNav from './EachOfferingNav'

const OfferingsToBride = () => {
    const [selectedCategory, setSelectedCategory] = useState('Day Party Make-up');
    const [selectedMakeup, setSelectedMakeup] = useState(
        {
            "title": "Regular Makeup",
            "description": "Apple",
            "base": "Base with Forever-52 (liquid) / powder Forever-52 loose powder",
            "eyeMakeup": [
                "2 eyeshadow, brown eye-makeup",
                "2 eyeshadow, shine + matte",
                "Brown smokey",
                "Nude eyemake-up (dewy base)"
            ],
            "priceRange": "2k-2.5k",
            "route" : "regular-day-party-makeup"
        }
    )

    const [data, setData] = useState([
        {
            "title": "Day Party Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Base with Forever-52 (liquid) / powder Forever-52 loose powder",
                    "eyeMakeup": [
                        "2 eyeshadow, brown eye-makeup",
                        "2 eyeshadow, shine + matte",
                        "Brown smokey",
                        "Nude eyemake-up (dewy base)"
                    ],
                    "priceRange": "2k-2.5k",
                    "route" : "regular-day-party-makeup"
                },
                {
                    "title": "Reasonable Make-up",
                    "description": "Apple",
                    "base": "Base with Maybelline fitme foundation / powder fit me loose powder",
                    "eyeMakeup": [
                        "2 eyeshadow, brown eye-makeup",
                        "2 eyeshadow, shine + matte",
                        "Brown smokey",
                        "Nude eyemake-up (dewy base)"
                    ],
                    "priceRange": "1.5k",
                    "route" : "reasonable-day-party-makeup"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "PAC liquid HD foundation / powder - HD powder PAC",
                    "eyeMakeup": [
                        "2 eyeshadow, brown eye-makeup",
                        "2 eyeshadow, shine + matte",
                        "Brown smokey",
                        "Nude eyemake-up (dewy base)"
                    ],
                    "priceRange": "3-4k",
                    "route" : "hd-day-party-makeup"
                },
            ]
        },
        {
            "title": "Night Party Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist liquid foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Smokey eye-makeup",
                        "Winged Smokey",
                        "Nude eye-makeup",
                        "Any of her choice",
                    ],
                    "priceRange": "2.5k",
                    "route" : "regular-night-party-makeup"
                },
                {
                    "title": "Basic HD Make-up",
                    "description": "Apple",
                    "base": "PAC HD foundation / Powder - HD powder",
                    "eyeMakeup": [
                        "Smokey eye-makeup",
                        "Winged Smokey",
                        "Nude eye-makeup",
                        "Any of her choice",
                    ],
                    "priceRange": "3k-3.5k",
                    "route" : "basic-hd-night-party-makeup"
                },
                {
                    "title": "Advance HD Make-up",
                    "description": "Apple",
                    "base": "Huda beauty makeup / Powder - HD powder",
                    "eyeMakeup": [
                        "Glitter eyemakeup",
                        "Any of her choice with glitters"
                    ],
                    "priceRange": "4k-5k",
                    "route" : "advance-hd-night-party-makeup"
                },
            ]
        },
        {
            "title": "Haldi Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 ultradefinition foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "3-4 eyeshadow eye-make-up",
                        "1 colorful eye-make-up",
                        "2 colorful cut crease eye-make-up",
                        "1 colorful eye-makeup with glitters",
                    ],
                    "priceRange": "2-2.5k",
                    "route" : "regular-haldi-makeup"
                },
                {
                    "title": "Reasonable Make-up",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "1 eyeshadow (shine + matter)",
                        "2 eyeshadow (shine + matte)",
                        "As per outfit"
                    ],
                    "priceRange": "1.5k",
                    "route" : "reasonable-haldi-makeup"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "3-4 eyeshadow eye-makeup",
                        "1 colorful eye-makeup",
                        "2 colorful cut crease eye-makeup",
                        "1 colorful eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "3k-4k",
                    "route" : "hd-haldi-makeup"
                },
                {
                    "title": "Advance HD Make-up",
                    "description": "Apple",
                    "base": "Base with Huda Beauty HD Foundation",
                    "eyeMakeup": [
                        "2 colorful cut crease eye-makeup with glitter",
                        "1 colorful eye-makeup with glitters",
                        "colorful blur cut crease eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "4k-5k",
                    "route" : "advance-hd-haldi-makeup"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "2 colorful cut crease eye-makeup with glitter",
                        "1 colorful eye-makeup with glitters",
                        "colorful blur cut crease eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "7k-8k",
                    "route" : "airbrush-haldi-makeup"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "2 colorful cut crease eye-makeup with glitter",
                        "1 colorful eye-makeup with glitters",
                        "colorful blur cut crease eye-makeup with glitters",
                        "Any of her choice"
                    ],
                    "priceRange": "8k-10k",
                    "route" : "airbrush-hd-haldi-makeup"
                },
            ]
        },
        {
            "title": "Sangeet Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "2 eyeshadow with glitters",
                        "Pink soft smokey with glitter",
                        "Brown blur cut crease"
                    ],
                    "priceRange": "2.5k",
                    "route" : "regular-sangeet-makeup"
                },
                {
                    "title": "Reasonable Make-up",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "Soft smokey",
                        "Pink and silver",
                        "Brown shine and matte",
                        "Brown blur cut crease",
                    ],
                    "priceRange": "1.5k",
                    "route" : "reasonable-sangeet-makeup"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "2 eyeshadow with glitters",
                        "Pink soft smokey with glitter",
                        "Brown blur cut crease",
                        "Soft smokey",
                        "Pink and silver",
                        "Brown shine and matte",
                    ],
                    "priceRange": "3k-4k",
                    "route" : "hd-sangeet-makeup"
                },
                {
                    "title": "Advance HD Make-up",
                    "description": "Apple",
                    "base": "Huda Beauty HD Foundation",
                    "eyeMakeup": [
                        "Cut crease",
                        "Colorful smokey",
                        "Pink smokey",
                        "Halo eyes brown",
                    ],
                    "priceRange": "4k-5k",
                    "route" : "advance-hd-sangeet-makeup"
                },
                {
                    "title": "AirBrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Cut crease",
                        "Colorful smokey",
                        "Pink smokey",
                        "Halo eyes brown",
                    ],
                    "priceRange": "7k-8k",
                    "route" : "airbrush-sangeet-makeup"
                },
                {
                    "title": "AirBrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Cut crease",
                        "Colorful smokey",
                        "Pink smokey",
                        "Halo eyes brown",
                    ],
                    "priceRange": "8k-10k",
                    "route" : "airbrush-hd-sangeet-makeup"
                },
            ]
        },
        {
            "title": "Engagement Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist liquid foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Colorful smokey with shine",
                        "Pink sharp halo cut crease",
                        "Brown classic partial smokey"
                    ],
                    "priceRange": "2-2.5k",
                    "route" : "regular-engagement-makeup"
                },
                {
                    "title": "Reasonable Make-up",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "Nude eye-makeup with liner",
                        "2 eyeshadow",
                        "2 eyeshadow colorful with shine",
                        "matte smokey"
                    ],
                    "priceRange": "1.5k",
                    "route" : "reasonable-engagement-makeup"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "3k",
                    "route" : "hd-engagement-makeup"
                },
                {
                    "title": "Advance HD Make-up",
                    "description": "Apple",
                    "base": "Base with Huda Beauty / Forever-52 multitasker palette",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "4k-5k",
                    "route" : "advance-hd-engagement-makeup"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "7k-8k",
                    "route" : "airbrush-engagement-makeup"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "8k-10k",
                    "route" : "airbrush-hd-engagement-makeup"
                },

            ]
        },
        {
            "title": "Cocktail Party Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Base with Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Winged brown soft smokey",
                        "Black soft smokey",
                        "Spot light halo",
                        "Blur cut crease"
                    ],
                    "priceRange": "2.5k",
                    "route" : "regular-cocktail-party-makeup"
                },
                {
                    "title": "Reasonable Make-up",
                    "description": "Apple",
                    "base": "Maybelline fitme liquid foundation / Powder - loose powder fit me mayebelline",
                    "eyeMakeup": [
                        "Nude eye-makeup with liner",
                        "2 eyeshadow",
                        "2 eyeshadow colorful with shine",
                        "matte smokey"
                    ],
                    "priceRange": "1.5k",
                    "route" : "reasonable-cocktail-party-makeup"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "PAC HD Liquid foundation",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "3k",
                    "route" : "hd-cocktail-party-makeup"
                },
                {
                    "title": "Advance HD Make-up",
                    "description": "Apple",
                    "base": "Base with Huda Beauty / Forever-52 multitasker palette",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "4k-5k",
                    "route" : "advance-hd-cocktail-party-makeup"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "7k-8k",
                    "route" : "airbrush-cocktail-party-makeup"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Colorful cut crease with glitters",
                        "Brown sharp halo cut crease",
                        "Cut crease with colorful glitters",
                        "Eye make-up with smudge liners",
                    ],
                    "priceRange": "8k-10k",
                    "route" : "airbrush-hd-cocktail-party-makeup"
                },
            ]
        },
        {
            "title": "Traditional Bride Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo"
                    ],
                    "priceRange": "15-18k",
                    "route" : "regular-makeup-for-traditional-bride"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "Huda Beauty HD foundation",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo",
                    ],
                    "priceRange": "20k",
                    "route" : "hd-makeup-for-traditional-bride"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo",
                    ],
                    "priceRange": "22k",
                    "route" : "airbrush-makeup-for-traditional-bride"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo",
                    ],
                    "priceRange": "22k-25k",
                    "route" : "airbrush-hd-makeup-for-traditional-bride"
                },
            ]
        },
        {
            "title": "Bengali Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo"
                    ],
                    "priceRange": "15-18k",
                    "route" : "regular-makeup-for-bengali-bride"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "Huda Beauty HD foundation",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo",
                    ],
                    "priceRange": "20k",
                    "route" : "hd-makeup-for-bengali-bride"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo",
                    ],
                    "priceRange": "22k",
                    "route" : "airbrush-makeup-for-bengali-bride"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "Brown partial smokey with winged liner",
                        "2 eye shadow with glitter",
                        "Spot light halo",
                    ],
                    "priceRange": "22k-25k",
                    "route" : "airbrush-hd-makeup-for-bengali-bride"
                },
            ]
        },
        {
            "title": "Maharashtrian Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Cut crease",
                        "Smokey eyes with glitters"
                    ],
                    "priceRange": "15-18k",
                    "route" : "regular-makeup-for-maharashtrian-bride"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "Huda Beauty HD foundation",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Cut crease",
                        "Smokey eyes with glitters"
                    ],
                    "priceRange": "20k",
                    "route" : "hd-makeup-for-maharashtrian-bride"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Cut crease",
                        "Smokey eyes with glitters"
                    ],
                    "priceRange": "22k",
                    "route" : "airbrush-makeup-for-maharashtrian-bride"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Cut crease",
                        "Smokey eyes with glitters"
                    ],
                    "priceRange": "22k-25k",
                    "route" : "airbrush-hd-makeup-for-maharashtrian-bride"
                },
            ]
        },
        {
            "title": "Muslim Bride Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Pink Spot light halo",
                        "Soft Smokey eyes with glitters"
                    ],
                    "priceRange": "15-18k",
                    "route" : "regular-makeup-for-muslim-bride"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "Huda Beauty HD foundation",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Pink Spot light halo",
                        "Soft Smokey eyes with glitters"
                    ],
                    "priceRange": "20k",
                    "route" : "hd-makeup-for-muslim-bride"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Pink Spot light halo",
                        "Soft Smokey eyes with glitters"
                    ],
                    "priceRange": "22k",
                    "route" : "airbrush-makeup-for-muslim-bride"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Pink Spot light halo",
                        "Soft Smokey eyes with glitters"
                    ],
                    "priceRange": "22k-25k",
                    "route" : "airbrush-hd-makeup-for-muslim-bride"
                },
            ]
        },
        {
            "title": "South Indian Bride Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Half cut crease",
                        "Half cut with glitters"
                    ],
                    "priceRange": "15-18k",
                    "route" : "regular-makeup-for-south-indian-bride"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "Huda Beauty HD foundation",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Half cut crease",
                        "Half cut with glitters"
                    ],
                    "priceRange": "20k",
                    "route" : "hd-makeup-for-south-indian-bride"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Half cut crease",
                        "Half cut with glitters"
                    ],
                    "priceRange": "22k",
                    "route" : "airbrush-makeup-for-south-indian-bride"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "Half cut crease",
                        "Half cut with glitters"
                    ],
                    "priceRange": "22k-25k",
                    "route" : "airbrush-hd-makeup-for-south-indian-bride"
                },
            ]
        },
        {
            "title": "Christian Bride Make-up",
            "types": [
                {
                    "title": "Regular Make-up",
                    "description": "Apple",
                    "base": "Forever-52 pro-artist foundation / Powder - loose powder Forever-52",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "--",
                        "--",
                    ],
                    "priceRange": "15-18k",
                    "route" : "regular-makeup-for-christian-bride"
                },
                {
                    "title": "HD Make-up",
                    "description": "Apple",
                    "base": "Huda Beauty HD foundation",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "--",
                        "--",
                    ],
                    "priceRange": "20k",
                    "route" : "hd-makeup-for-christian-bride"
                },
                {
                    "title": "Airbrush Make-up",
                    "description": "Apple",
                    "base": "Airbrush",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "--",
                        "--",
                    ],
                    "priceRange": "22k",
                    "route" : "airbrush-makeup-for-christian-bride"
                },
                {
                    "title": "Airbrush + HD Make-up",
                    "description": "Apple",
                    "base": "Airbrush + Huda Beauty",
                    "eyeMakeup": [
                        "--",
                        "--",
                        "--",
                        "--",
                    ],
                    "priceRange": "22k-25k",
                    "route" : "airbrush-hd-makeup-for-christian-bride"
                },
            ]
        },


    ])

    const selectedMakeupHandler = (category, k) => {
        setSelectedCategory(category);
        setSelectedMakeup(k);
    }

    const onCallRequest = () => {
        document.getElementById("sendQueryOnBridal").click();
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                <div className={styles.menuWrapper}>
                    {data.map((item, index) => (
                        <EachOfferingNav
                            item={item}
                            key={index}
                            category={item.title}
                            selectedCategory={selectedCategory}
                            selectedMakeupHandler={selectedMakeupHandler}
                            selectedMakeup={selectedMakeup}
                        />
                    ))}
                </div>


                <div className={styles.contentWrapper}>
                    <div className={styles.selectedContent}>
                        <div className={styles.selectedTitle}>
                            <h2>
                                {selectedCategory}
                            </h2>
                            <h3>
                                {selectedMakeup.title}
                            </h3>
                        </div>
                        <div>
                            <div className={styles.expertiseTxt}>
                                <div className={styles.baseContainer}>
                                    <h5>Base : </h5>
                                    <p>
                                        {selectedMakeup.base}
                                    </p>
                                </div>
                                <div className={styles.eyeMakeupContainer}>
                                    <h5>Eye Make-up : </h5>
                                    {selectedMakeup.eyeMakeup &&
                                        <ul>
                                            {selectedMakeup.eyeMakeup.map((it, indexIt) => (
                                                <li key={indexIt+"IT"}>{it}</li>
                                            ))}
                                        </ul>
                                    }
                                </div>

                                <div className={styles.whatIncludedContainer}>
                                    <h5>What's included?</h5>
                                    <ul>
                                        <li>Saree Draping / Attire / Dressing</li>
                                        <li>Hair do</li>
                                        <li>Premium Eye lashes</li>
                                        <li>Premium Eye lenses</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                ₹{selectedMakeup.priceRange}
                            </div>
                            <div className={styles.actionWrapper}>
                                <div>
                                    {/* <a 
                                        href="tel:+91-8929460356"
                                        className={styles.actionIcon}
                                    >
                                        <IoIosCall color="black" size={24} />
                                    </a>
                                    <a href={`https://wa.me/918929460356?text=Hey%20Lokaci,%0AI%20want%20to%20know%20more%20about%20*` + selectedMakeup.title + `*%20for%20` + selectedCategory + `%20for%20my%20wedding!%0AThanks`} className={styles.actionIcon}
                                        target="_blank"
                                        className={styles.actionIcon}
                                    >
                                        <IoLogoWhatsapp color="green" size={24} />
                                    </a> */}
                                </div>
                                <div>
                                    {/* <Link className={styles.bookDemo}
                                        // to={'book-demo/'+selectedMakeup.route}
                                    >
                                        Book Demo
                                    </Link> */}
                                    <button className={styles.bookNow}
                                        onClick={() => onCallRequest()}
                                    >
                                        Book Now
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default OfferingsToBride
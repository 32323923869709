import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../Footer/MainFooter.module.css'

const MainFooter = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                <div className={styles.mainContentContainer}>
                    <div className={styles.leftContainer}>
                        <div className={styles.smallLogo}>
                            <img src="https://lokaci.s3.ap-south-1.amazonaws.com/lokaci_logo_white.png" alt="logo-official-lokaci" />
                        </div>
                        <div>
                            <div className={styles.madeWithLove}>
                                Made with Love in India
                            </div>
                        </div>
                        <div className={styles.eachCompound}>
                            <div className={styles.compoundTitle}>
                                <h3>Who we are?</h3>
                            </div>
                            <div className={styles.compoundContent}>
                                <p>
                                    We are India's leading salon solution platform
                                </p>
                            </div>
                        </div>

                        <div className={styles.eachCompound}>
                            <div className={styles.compoundTitle}>
                                <h3>Why choose us?</h3>
                            </div>
                            <div className={styles.compoundContent}>
                                <p>
                                    We are the India's real-time salon booking platform.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.eachCol}>
                            <div className={styles.colTitle}>
                                <h4>
                                    Lokaci
                                </h4>
                            </div>
                            <div className={styles.colContent}>
                                <div className={styles.eachLink}>
                                    About
                                </div>
                                <div className={styles.eachLink}>
                                    Career
                                </div>
                            </div>
                        </div>

                        <div className={styles.eachCol}>
                            <div className={styles.colTitle}>
                                <h4>
                                    Apps
                                </h4>
                            </div>
                            <div>
                                <div className={styles.eachLink}>
                                    Lokaci App
                                </div>
                                <div className={styles.eachLink}>
                                    Salon App
                                </div>
                                <div className={styles.eachLink}>
                                    Vendor Lite App
                                </div>
                            </div>
                        </div>

                        <div className={styles.eachCol}>
                            <div className={styles.colTitle}>
                                <h4>
                                    Help Center
                                </h4>
                            </div>
                            <div>
                                <div className={styles.eachLink}>
                                    Help
                                </div>
                                <div className={styles.eachLink}>
                                    FAQs
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={styles.copyNPrivacyWrapper}>
                    <div className={styles.copyrightWrapper}>
                        © 2022 Lokaci | All Rights Reserved.
                    </div>
                    <div className={styles.privacyWrapper}>
                        <div className={styles.eachPrivacyUrl}>
                            <Link to="/privacy">Privacy Policy</Link>
                        </div>
                        <div className={styles.eachPrivacyUrl}>
                            <Link to="/terms-of-use">Terms of Use</Link>
                        </div>
                        <div className={styles.eachPrivacyUrl}>
                            <Link to="/about">About Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainFooter
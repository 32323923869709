import React from 'react'
import styles from '../BridalMakeups/BridalReviewCard.module.css'
import { FaStar } from "react-icons/fa";

const BridalReviewCard = props => {
    let reviewData = props.reviewData;
    return (
        <div className={styles.mainContainer}>
            <div>
                <div className={styles.reviewMainTxt}>
                    <h4>
                        {reviewData.comment}
                    </h4>
                </div>
                <div className={styles.reviewer}>
                    - {reviewData.reviewer}
                </div>
            </div>
            <div className={styles.lowerSection}>
                <div>
                    -
                </div>
                <div className={styles.starRatingContainer}>
                    <FaStar size={18} color="#da1884" />
                    <FaStar size={18} color="#da1884" />
                    <FaStar size={18} color="#da1884" />
                    <FaStar size={18} color="#da1884" />
                    <FaStar size={18} color="#da1884" />
                </div>
            </div>
        </div>
    )
}

export default BridalReviewCard
import React from 'react'
import styles from '../Footer/FooterAtBridalMakeups.module.css'
import { BsInstagram } from "react-icons/bs";



const FooterAtBridalMakeups = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                <div className={styles.secSocial}>
                <BsInstagram size={30} />
                </div>
                <div className={styles.secCopyright}>
                    <p>&#169; 2022 Lokaci Private Limited</p>
                    <p>India</p>
                </div>
            </div>
        </div>
    )
}

export default FooterAtBridalMakeups
import React, { useEffect, useRef, useState } from 'react'
import AtBridalMakeups from '../../Components/Headers/AtBridalMakeups'
import styles from '../Makeups/BridalMakeups.module.css'
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import EachPortraitFrame from '../../Components/Gallery/EachPortraitFrame';
import PhotoGallery from '../../Components/Gallery/PhotoGallery';
import SendQuery from '../../Components/BridalMakeups/SendQuery';
import FooterAtBridalMakeups from '../../Components/Footer/FooterAtBridalMakeups';
import TopMakeupLooks from '../../Components/BridalMakeups/TopMakeupLooks';
import BridalReviewSection from '../../Components/BridalMakeups/BridalReviewSection';
import OtherValuableServicesForBrides from '../../Components/BridalMakeups/OtherValuableServicesForBrides';
import PlacesAtBridal from '../../Components/BridalMakeups/PlacesAtBridal';
import BridalServicesOfferings from '../../Components/BridalMakeups/BridalServicesOfferings';
import InPageFAQs from '../../Components/FAQs/InPageFAQs';
import OfferingsToBride from '../../Components/BridalMakeups/OfferingsToBride';
import OfferingsToGroom from '../../Components/BridalMakeups/OfferingsToGroom';
import OfferingsToFriendNFamily from '../../Components/BridalMakeups/OfferingsToFriendNFamily';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga';


const BridalMakeups = () => {
    let screenWidth = window.innerWidth;
    let landscapeVideo = 'https://lokaci.s3.ap-south-1.amazonaws.com/header-bridal-video_compress.mp4#t=0.1';
    let portraitVideo = 'https://lokaci.s3.ap-south-1.amazonaws.com/header-bridal-video-vertical_compress.mp4#t=0.1';
    let landscapeThumbnail = 'https://lokaci.s3.ap-south-1.amazonaws.com/portrait-thumbnail-bridal.webp';
    let portraitThumbnail = 'https://lokaci.s3.ap-south-1.amazonaws.com/landscape-thumbnail-bridal.webp';
    
    // let landscapeVideo = 'https://res.cloudinary.com/drotwjbzs/video/upload/v1667495253/lokaci-web-header/header-bridal-video_mxabiy.mp4#t=0.1';
    // let portraitVideo = 'https://res.cloudinary.com/drotwjbzs/video/upload/v1667495788/lokaci-web-header/header-bridal-video-vertical_kd0yry.mp4#t=0.1';
    // let landscapeThumbnail = 'https://res.cloudinary.com/iamironman/image/upload/v1667533276/landscape-thumbnail-red_bnmmmi.webp';
    // let portraitThumbnail = 'https://res.cloudinary.com/iamironman/image/upload/v1667533099/portrait-thumbnail-red_ywkjum.webp';

    const [showVideo, setShowVideo] = useState(false)
    const ref = useRef();
    const refOfferingHeader = useRef();
    const refOfferingHeaderContainer = useRef();
    const scrollMenuRef = useRef();
    const [offeringsSelected, setOfferingsSelected] = useState('Bride');
    const [galleryFilterSelected, setGalleryFilterSelected] = useState('All');
    const [looksArr, setLooksArr] = useState([
        'Day Party Bridal Look',
        'Night Party Bridal Look',
        'Haldi Makeup',
        'Sangeet Makeup',
        'Engagement Makeup',
        'Cock-tail party Makeup',
        'Traditional Bridal Makeup'
    ])
    const [offeringTitleFixed, setOfferingTitleFixed] = useState(false)
    const [callbackRequest, setCallbackRequest] = useState(false)

    useEffect(() => {
        ref.current.style.animation = "bounce-7 2s forwards infinite";

        setTimeout(() => {
            setShowVideo(true);
        }, 6000);
    }, [])


    const scrollLeftRight = (side) => {
        let currentScroll = scrollMenuRef.current.scrollLeft;
        if (side == 'Left') {
            scrollMenuRef.current.scrollLeft = currentScroll - 250;
        } else {
            scrollMenuRef.current.scrollLeft = currentScroll + 250;
        }
    }


    const [faqsData, setFaqsData] = useState([
        {
            "question": "How much does Bridal Makeup from Lokaci cost?",
            "answer": "The cost depends on the look you are choosing. Lokaci offers variety of bridal looks, ranging from regular bridal look to Airbrush Bridal Makeup. The cost even differ on the artist you are prefering, eye makeup you would like to have, add on services, etc. The Bridal Makeup usually cost between 10K to 35K at Lokaci. It might go higher in case you want booking for outstation makeup.",
        },
        {
            "question": "How much does Party Makeup from Lokaci cost?",
            "answer": "The party Makeup starts at 1500 at Lokaci and it goes up till 8000 in case of advance makeup and luxury products. The price differ on the base and eye makeup you want to go or any add on services you would like to have.",
        },
        {
            "question": "What makeup looks can I get at Lokaci?",
            "answer": "At Lokaci, you'll get all the makeup looks starting from regular party makeup to celebrity bridal makeup look. We have makeup looks for every occassion such as Day Party, Night Party, Engagement, Haldi, Sangeet, Mehendi, Cocktail, Bridal, etc. Also, you get bridal makeup from different cultures like Marathi Bride, Bengali Bride, Christain Bride, Traditional Indian Bride, South Indian Bride, Muslim Bride, etc. Just tell us you requirement and our team will get the best makeup look for you.",
        },
        {
            "question": "Can I get Destination Makeup from Lokaci?",
            "answer": "Yes, you can get booking for Destination Makeup too at Lokaci. We have the best bridal makeup services available Pan India.",
        },
        {
            "question": "What makeup bands do you use?",
            "answer": "We use variety of makeup brands suiting every customer budget. Our makeup vanity has the best brands such as Forever 52, Pac, Huda Beauty, Anastasia Beverly Hills, Sephora, NYX, Temptu, Chanel, Makeup Revolution, Bobbi Brown, M.A.C, L.A Girl, Sugar, etc.",
        },
        {
            "question": "How many years of experience does the Makeup Artist from Lokaci have?",
            "answer": "All the makeup artist at Lokaci have more that four plus years experience in the Industry. In the case of Senior Makeup Artist, the exeprience goes up to more than eight years in the Industry. The Makeup Artist at Lokaci have completed theirs studies from renowned institutions and received special training from the Lokaci expert trainers. All the makeup artist at Lokaci, receives special training regularly to become expert with the growing trending makeup and give our client the best services on their D Day.",
        },
        {
            "question": "What Bridal Services are offered by Lokaci?",
            "answer": "Lokaci offers variety of bridal services, starting from the skin preparation for the bride. You'll get variety of pre-bridal packages, makeup bookings, hairstlying services, nail services, hair treatment, beauty services, mehendi, etc. at Lokaci. Apart from bride, you can avail services for the relatives and guests too. The brides at Lokaci get a knowledgeable consultation form our Beauty Advisors who guide them in the every step and provide them with the best servies to make their wedding preparation hassle free.",
        },
        {
            "question": "Why should I choose Lokaci?",
            "answer": "Apart from providing the best servies, there are multiple benefits of choosing Lokaci: 1. One Place for everything: From pre bridal services to getting amazing makeup and hair services, you'll get everything at Lokaci. 2. Best Prices: Get the best prices at Lokaci, which are way lower than the market.  3. Expert Consulation: Before booking services, get consultation with our experts to find out the best services as per your skin type and requirements. 4. Services from the Expert: From pre-bridal to makeup, you'll get services from the handpicked experts at Lokaci. 5. Add on Services: Get add on services at Lokaci, just tell us your requirement, we'll arrange everything from finding the best lenses to helping you pick the best hairstyle as per your outfit.  6. Get every service: From the trending facials to trending makeup looks, you'll get everything at Lokaci. Lokaci offers some handpicked services, which aren't available anywhere in the market.  7. Complete package: Lokaci bridal packages contains every services such as pre-bridal, hair, makeup, mehendi, nail and bridal consultation, etc.  8.  Trial before final booking: Get an exclusive trial with us before making the final booking.",
        },
        {
            "question": "How much time prior do I need to book the appointment?",
            "answer": "You need to book appointment atleast 1 week prior in case of outstation services, whereas in case of other services you can get appointment booked on immediate basis, considering our team availability.  It's better to book appointment atleast 15 days prior to get the benefit of consultation and other add on services.",
        },
        {
            "question": "How to book the appointment?",
            "answer": "Register using the form given on our website or whatsapp message us on XXX number to book an appointment with our team for consultation.",
        },
        {
            "question": "Can I get pre-bridal services too?",
            "answer": "Yes, absolutely you can get pre-bridal services at Lokaci including skin, hair and nail services. Our team makes personalised packages matching your requirements. The services are also available for the guest and relatives.",
        },
        {
            "question": "Can I get appointment for guest/bridesmaid services too?",
            "answer": "At Lokaci, you can get appointment for family services too. Just let our team know your requirement and an estimated number of people to get the benefits. The guest and bridesmaid can even get personalized services as per their need. We ensure that not only the bride but everyone at the wedding looks spectacular and cherishes the memories.",
        },
        {
            "question": "Can I get a Mehendi Artist with Lokaci?",
            "answer": "Get the best Mehendi Services at Lokaci such as Arabic, Simple, Bridal, Rajasthani, Marwadi, etc. All the designs are done by our expert Mehendi Artist with a great experience in the Industry. You can book appointment for every occassion with us.",
        },
        {
            "question": "What is included in Bridal Makeup?",
            "answer": "Bridal Makeup includes brides mesmerizing makeup look, hairstyling, lenses, lashes, dress setting, saree draping, dupatta draping and all the other things required for the final look. Our main priority is giving the best look to our client, no matter whatever is required to be included to achieve that. Just focus on your D-Day, our team has got you covered.",
        },
        {
            "question": "How much extra do I have to pay for the destination makeup?",
            "answer": "The charges for distination makeup depends on the location, no.of occassions and people. Please consult with our Beauty Advisor by letting them know the location. So, that they can let you know about the extra charges for that particular location.",
        },
        {
            "question": "Do I have to pay some amount in Advance to book the appointment?",
            "answer": "Yes, you have to pay some advance amount to book the apppointment. The rest can be paid after availing the services. The advance payment amount differ from services to services such as in case of pre-bridal services you can pay after availing the services whereas in case of Makeup booking you have to deposit some advance to book your slot.",
        },
        {
            "question": "Will I get free trial before booking an appointment with Lokaci?",
            "answer": "Surely, you can get a free trial with us for Makeup Appointment. We want you to look best on your Wedding day, Trial would help you in choosing the best makeup look and makes your look mesmerizing on your wedding day.",
        },
        {
            "question": "What additional services can I get at Lokaci?",
            "answer": "You'll get all the beauty services at Lokaci such as skin, makeup, hair and nail services. Apart from this you even get other additional services, required to give the final look.",
        },
        {
            "question": "How much does makeup cost for guest/bridesmaid?",
            "answer": "The cost is usuallt between 1500 to 6000. The pricing depends on the look, products and add on services they are preferring. Also, in case of more people you can extra additional discount and some free services.",
        },
        {
            "question": "Will I get hairstyling services too at Lokaci?",
            "answer": "Yes, you'll get both the regular and advance haistyling services at Lokaci. For every occassion, you'll get the best hairstyle to go with your final look.",
        },
        {
            "question": "How much does Hairstyling services at Lokaci cost?",
            "answer": "Hairstyling services starts at 500 for regular hairstyles and goes upto 3000 in case of advance Bridal Hairstyling. The cost might even goes upto in case off add on hair accessories and hair extensions in case required to complete the look.",
        },
    ])

    let faqMainEntity = [];
    faqsData.map((item, index) => {
        let p =
        {
            "@type": "Question",
            "name": item.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": item.answer
            }
        }


        faqMainEntity.push(p);
    });


    const handleScroll = () => {
        const position = refOfferingHeaderContainer.current.offsetTop;
        const curScrolled = window.pageYOffset;
        let p = document.getElementById("wwaoContentBridalMakeup").offsetHeight;

        if (screenWidth < 600) {
            if (curScrolled > position) {
                setOfferingTitleFixed(true);
            }

            if (curScrolled > (position + p + 100)) {
                setOfferingTitleFixed(false);
            }

            if (curScrolled < (position - 100)) {
                setOfferingTitleFixed(false);
            }
        }
    }

    const requestCallBackHandler = (k) => {
        setCallbackRequest(k)
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <Helmet>
                <title>Book Best Bridal Makeup for your wedding with Lokaci </title>
                <meta name="description" content="India's best bridal makeup booking site. We are trained, professional, smart, and confident to makeup to look Goddess on your wedding day. Book your bridal makeup with Lokaci today."></meta>
                <meta name="keywords" content="lokaci, bridal make-up, salon services, pre-bridal package, family make-up, bridal services, make-artist, trending make-up look, traditional bride, south india bridal look"></meta>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "Introducing the bridal make-up for wedding",
                        "description": "This wedding season, Lokaci is introducing the bridal make-up in India, the world's largest beauty/make-up/hair aspirants and salons community. ",
                        "thumbnailUrl": ["`+ landscapeThumbnail + `", "` + portraitThumbnail + `"],
                        "uploadDate": "2022-11-01T08:00:00+05:30",
                        "duration": "PT0M54S",
                        "contentUrl": "`+ landscapeVideo + `",
                        "embedUrl": "`+ landscapeVideo + `",
                        "interactionStatistic":
                        {
                            "@type": "InteractionCounter",
                            "interactionType":
                            {
                                "@type": "WatchAction"
                            },
                            "userInteractionCount": 56018
                        },
                        "regionsAllowed": "IN,US,NL"
                    }`
                    }
                </script>


                <script type="application/ld+json">
                    {`{
                        
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": `+ JSON.stringify(faqMainEntity) + `
                      
                    }`}
                </script>

            </Helmet>

            <AtBridalMakeups
                requestCallBackHandler={requestCallBackHandler}
            />
            <div className={styles.heroVideoWrapper}>
                <div className={styles.heroVideoContainer}>
                    {/* <video
                        className={styles.heroVideo}
                        // autoPlay={true}
                        muted={true}
                        loop={true}
                        src={screenWidth > 500 ? "https://res.cloudinary.com/iamironman/video/upload/v1666494745/background-videos/Render_Comp-bride_1_lqo2yz.mp4" : "https://res.cloudinary.com/drotwjbzs/video/upload/v1667370338/lokaci-website-bridal-1080x1920_izgz4z.mp4"}
                    >

                    </video> */}
                    {showVideo == true ?
                        <video
                            preload="metadata"
                            className={styles.heroVideo}
                            muted={true}
                            loop={true}
                            autoPlay
                        >
                            <source src={screenWidth > 500 ? landscapeVideo : portraitVideo} type="video/mp4" />
                        </video>
                        :
                        <img
                            src={screenWidth > 500 ? landscapeThumbnail : portraitThumbnail} alt="video thumbnail"
                            className={styles.thumbnailImg}
                            alt="video thumbnail"
                        />
                    }
                </div>
                <div className={styles.heroVideoLayer}>
                    <button className={styles.bookDemoBtn}
                        ref={ref}
                        onClick={() => document.getElementById("sendQueryOnBridal").click()}
                    >
                        Book A Demo Now
                    </button>
                </div>
            </div>

            <div className={styles.whatWeAreOffering}>
                <div className={styles.wwaoContainer} ref={refOfferingHeaderContainer}>
                    <div className={offeringTitleFixed == true ? styles.wwaoTitleFixed : styles.wwaoTitle} ref={refOfferingHeader}>
                        <div className={styles.wwaoTitleTxt}>
                            <h2>
                                Our valuable offerings
                            </h2>
                        </div>
                        <div className={styles.offeringOptions}>
                            <div className={offeringsSelected === "Bride" ? styles.eachOfferingOptionBtnSelected : styles.eachOfferingOptionBtn}
                                onClick={() => setOfferingsSelected('Bride')}
                            >To Bride</div>
                            <div className={offeringsSelected === "Groom" ? styles.eachOfferingOptionBtnSelected : styles.eachOfferingOptionBtn}
                                onClick={() => setOfferingsSelected('Groom')}
                            >To Groom</div>
                            <div className={offeringsSelected === "Family" ? styles.eachOfferingOptionBtnSelected : styles.eachOfferingOptionBtn}
                                onClick={() => setOfferingsSelected('Family')}
                            >To Friends & Family</div>
                        </div>
                    </div>
                    <div className={styles.wwaoContent} id="wwaoContentBridalMakeup">
                        {offeringsSelected === "Bride" &&
                            <OfferingsToBride />
                        }

                        {offeringsSelected === "Groom" &&
                            <OfferingsToGroom />
                        }

                        {offeringsSelected === "Family" &&
                            <OfferingsToFriendNFamily />
                        }

                    </div>
                </div>
            </div>

            <div className={styles.galleryWrapper}>
                <div className={styles.galleryTitle}>
                    <div className={styles.galleryTitleTxt}>
                        <h2>
                            Looks Gallery
                        </h2>
                    </div>
                    <div className={styles.galleryOptions}>
                        <div className={galleryFilterSelected === "All" ? styles.eachGalleryOptionBtnSelected : styles.eachGalleryOptionBtn}
                            onClick={() => setGalleryFilterSelected('All')}
                        >All</div>
                        <div className={styles.scrollingMenu} ref={scrollMenuRef}>
                            {looksArr.map((itemLooks, index) => (
                                <div className={galleryFilterSelected === itemLooks ? styles.eachGalleryOptionBtnSelected : styles.eachGalleryOptionBtn}
                                    onClick={() => setGalleryFilterSelected(itemLooks)}
                                    key={index}
                                >{itemLooks}</div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.scrollOptionsNav}>
                        <div className={styles.eachScrollNavHandler}>
                            <FiChevronLeft size={24} className={styles.eachScrollBtn}
                                onClick={() => scrollLeftRight('Left')}
                                color="black"
                            />
                        </div>
                        <div className={styles.eachScrollNavHandler}>
                            <FiChevronRight size={24} className={styles.eachScrollBtn}
                                onClick={() => scrollLeftRight('Right')}
                                color="black"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.galleryContentWrapper}>
                    <div>
                        <PhotoGallery />
                    </div>
                </div>

            </div>

            <SendQuery
                callbackRequest={callbackRequest}
                requestCallBackHandler={requestCallBackHandler}
            />

            <BridalReviewSection />


            <OtherValuableServicesForBrides />

            <PlacesAtBridal />


            <div className={styles.reasonsWrapper}>
                <div className={styles.reasonsContainer}>
                    <div className={styles.reasonsHeader}>
                        <h1>Reasons to get bridal make-up with Lokaci on your wedding day!</h1>
                    </div>
                    <div>
                        <div className={styles.reasonIntro}>
                            <p>
                                <span className={styles.boldTxt}>'One Place for Everything',</span> From Pre-Bridal Services to getting amazing Makeup, Hair, Nail and Mehndi services,
                                you'll get everything at Lokaci that too at an affordable price.
                                Get free trial run before choosing your exquisite makeup look!! From Bride/Groom to family members, there is something for everyone!!
                            </p>
                        </div>

                        <div className={styles.uspContainer}>

                            <div className={styles.eachUspWrapper}>
                                <div>
                                    <h3>
                                        ONE PLACE FOR EVERYTHING
                                    </h3>
                                </div>
                                <div>
                                    <p>
                                        From Pre-Bridal Services to getting amazing Makeup, Hair, Nail and Mehendi services, you'll get everything at Lokaci.
                                    </p>
                                </div>
                            </div>

                            <div className={styles.eachUspWrapper}>
                                <div>
                                    <h3>
                                        BEST PRICES
                                    </h3>
                                </div>
                                <div>
                                    <p>
                                        Get the best prices at Lokaci, which are way lower than the market.
                                    </p>
                                </div>
                            </div>

                            <div className={styles.eachUspWrapper}>
                                <div>
                                    <h3>
                                        EXPERT SERVICES

                                    </h3>
                                </div>
                                <div>
                                    <p>
                                        From pre-bridal to makeup, you'll get services from the handpicked experts at Lokaci. .
                                    </p>
                                </div>
                            </div>

                            <div className={styles.eachUspWrapper}>
                                <div>
                                    <h3>
                                        ADD ON SERVICES
                                    </h3>
                                </div>
                                <div>
                                    <p>
                                        Get add-on services at Lokaci,  from finding the best lenses to helping client pick the best hairstyle as per their requirement.
                                    </p>
                                </div>
                            </div>

                            <div className={styles.eachUspWrapper}>
                                <div>
                                    <h3>
                                        GET TRENDING/SPECIALIZED SERVICES
                                    </h3>
                                </div>
                                <div>
                                    <p>
                                        From trending facials to trending makeup looks, you'll get everything at Lokaci. Lokaci offers some handpicked services, which aren't available anywhere in the market.
                                    </p>
                                </div>
                            </div>


                            <div className={styles.eachUspWrapper}>
                                <div>
                                    <h3>
                                        TRIAL BEFORE FINAL BOOKING
                                    </h3>
                                </div>
                                <div>
                                    <p>
                                        Get an exclusive trial with us before making the final booking.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>



            <InPageFAQs
                data={faqsData}
            />


            <BridalServicesOfferings />


            <TopMakeupLooks />

            <FooterAtBridalMakeups />

        </div>
    )
}

export default BridalMakeups
import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoIosCall, IoLogoWhatsapp } from 'react-icons/io';
import styles from '../BridalMakeups/EachOfferingNav.module.css'

const EachOfferingNav = props => {
    let category = props.category;
    let selectedCategory = props.selectedCategory;
    let selectedMakeup = props.selectedMakeup;
    let item = props.item;
    let selectedMakeupHandler = props.selectedMakeupHandler;
    const [isOpen, setIsOpen] = useState(false)

    const onCallRequest = () => {
        document.getElementById("sendQueryOnBridal").click();
    }

    return (
        <div className={category == selectedCategory ? styles.eachNavOptionSelected : styles.eachNavOption}>
            <div className={category == selectedCategory ? styles.menuTitleWrapperSelected : styles.menuTitleWrapper}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className={styles.navTitle}>
                    {item.title}
                </div>
                <div>
                    {isOpen == false ?
                        <FaChevronDown />
                        :
                        <FaChevronUp />
                    }
                </div>
            </div>
            {isOpen &&
                <div className={styles.typesWrapper}>
                    {item.types.map((itemTypes, indexTypes) => (
                        <div key={indexTypes}>
                            <div
                                className={selectedMakeup == itemTypes ? styles.eachTypeWrapperSelected : styles.eachTypeWrapper}
                                onClick={() => selectedMakeupHandler(category, itemTypes)}
                            >
                                {itemTypes.title}
                            </div>
                            <div className={styles.mobileViewDetails}>
                                <div className={styles.title}>
                                    {itemTypes.title}
                                </div>
                                <div>
                                    {/* {itemTypes.description} */}
                                </div>
                                <div>
                                    {itemTypes.base}
                                </div>
                                <div>
                                    <ul>
                                        {itemTypes.eyeMakeup.map((itemEye, indexEye) => (
                                            <li>{itemEye}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    ₹{itemTypes.priceRange}
                                </div>

                                <div className={styles.actionWrapper}>
                                    <div>
                                        {/* <a
                                            href="tel:+91-8929460356"
                                            className={styles.actionIcon}
                                        >
                                            <IoIosCall color="black" size={22} />
                                        </a>
                                        <a href={`https://wa.me/918929460356?text=Hey%20Lokaci,%0AI%20want%20to%20know%20more%20about%20*` + selectedMakeup.title + `*%20for%20` + selectedCategory + `%20for%20my%20wedding!%0AThanks`} className={styles.actionIcon}
                                            target="_blank"
                                            className={styles.actionIcon}
                                        >
                                            <IoLogoWhatsapp color="green" size={22} />
                                        </a> */}
                                    </div>
                                    <div>
                                        {/* <button className={styles.bookDemo}>
                                            Book Demo
                                        </button> */}
                                        <button className={styles.bookNow}
                                            onClick={() => onCallRequest()}
                                        >
                                            Book Now
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default EachOfferingNav
import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../PageNotFound/Error404.module.css'

const Error404 = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainBg}>
                <img src="https://www.pixel4k.com/wp-content/uploads/2018/09/galaxy-night-starry-sky-mountains-4k_1536017120.jpg" />
            </div>
            <div className={styles.mainContent}>
                <div className={styles.mainContentWrapper}>
                    <h2>404</h2>
                    <h1>
                        Oops,.. that's not the right address!
                    </h1>
                    <Link to="/" className={styles.goToHomeBtn}>Go To Home</Link>
                </div>
            </div>
        </div>
    )
}

export default Error404
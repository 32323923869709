import React from 'react'
import styles from '../BridalMakeups/BridalServicesOfferings.module.css';

const BridalServicesOfferings = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.title}>
                <h6>
                    Makeup Services we are offering:
                </h6>
            </div>
            <div className={styles.content}>
                <p>
                    Pre-bridal packages, Skin care treatments, Hair care treatments, Bridal mehandi,
                    Full bridal makeup, Bridal & family makeup, Groom makeup, Party makeup, Haldi makeup,
                    Engagement Makeup, Reception Party Makeup, Celebrity Makeup, Traditional Bridal Makeup
                </p>
            </div>
        </div>
    )
}

export default BridalServicesOfferings